import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { Flex, Divider, Button } from '@chakra-ui/react';
import { CalendarIcon } from '@chakra-ui/icons';
import 'react-datepicker/dist/react-datepicker.css';
import '.././../styles/styles.css';
import DateWithTitle from '../../components/DateComponent/DateWithTitle';
import style from './styles';
import styles from '../../components/common/style';
import { MiniHeader } from '../../components/common/customComponent';
import { renderCopyRight } from '../../components/aboutUsComponent/customCompoent';

const ApiLog = () => {
  const [startDate, setStartDate] = useState(new Date());
  return (
    <Flex flexDirection={'column'} background={'#fafdfb'} height={'100vh'}>
      <Flex {...styles.miniheader}>
        {MiniHeader({
          title: 'Api Report',
          path: 'Dashboard',
          path1: 'Reports',
          useSlash:'true',
          path2: 'Api Log',
        })}
      </Flex>

      {/* <Flex {...style.cardALContainer}> */}
      <Flex {...style.box} h={'100vh'} alignItems={'center'}>
        <Flex {...style.innerCard}>
          <Flex {...style.datesContainer} padding={10}>
            <DateWithTitle
              title={'Start Date'}
              style={style.dateComponentStyles}
            />
            <DateWithTitle
              title={'End Date'}
              style={style.dateComponentStyles}
            />
          </Flex>
          <Button {...style.downloadButton} border={0} ml={'5%'}>
            Download
          </Button>
        </Flex>
      </Flex>
      <Divider orientation="horizontal" />
      <Flex
        position={'sticky'}
        alignItems={'flex-end'}
        justifyItems={'flex-end'}
      >
        {renderCopyRight()}
      </Flex>
    </Flex>
  );
};

export default ApiLog;
