import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { Flex, Box, Text } from '@chakra-ui/react';
import { CalendarIcon } from '@chakra-ui/icons';
import 'react-datepicker/dist/react-datepicker.css';
// import '.././../styles/styles.css';
import styles from './styles';

const DateWithTitle = ({ title, style ,mandatory}) => {
  console.log('Style            ', style);
  const [startDate, setStartDate] = useState(new Date());
  return (
    <Flex {...style}>
      <Flex flexDirection={'row'}>
        <Text fontSize={'12'}>{title}</Text>
        {mandatory ? null : <Text color={'#ff0000'}>*</Text>}
      </Flex>
      <Flex {...styles.iconDateContainer}>
        <CalendarIcon
          w={4}
          h={4}
          color={'gray'}
          alignSelf={'center'}
          margin={'2'}
        />
        <Flex h={'100%'} borderColor={'lightgray'} borderWidth={'0.1px'}></Flex>
        <DatePicker
          selected={startDate}
          onChange={date => setStartDate(date)}
          className="react-datepicker__without_border_fullWH"
        />
      </Flex>
    </Flex>
  );
};
export default DateWithTitle;
