import React from 'react';
// import Select , {components} from 'react-select';
import { Select, Flex, Text, Skeleton } from '@chakra-ui/react';
import theme from '../../config/color';

export const dropdownCustom = ({
  title,
  width,
  isMulti,
  temparray,
  onChange,
  isDisabled,
  mandatory,
  placeholder,
}) => {
  return (
    <Flex flexDirection={'column'}>
      <Flex flexDirection={'row'}>
        <Text
          color={theme.customColors.blackAdminColor['100']}
          fontSize="md"
          marginBottom={'2'}
        >
          {title}
        </Text>
        {mandatory ? (
          <Text color={'red'} marginLeft={'1'}>
            *
          </Text>
        ) : null}
      </Flex>
      <Select
        width={width}
        isDisabled={isDisabled}
        isMulti={isMulti}
        onChange={onChange}
        placeholder={placeholder}
        color={theme.customColors.blackAdminColor['100']}
        fontSize={{ base: '15px', md: '14px', lg: '12px' }}
        // fontSize={{ base: '15px', md: '40px', lg: '50px' }}
      >
        {temparray &&
          temparray.map((value, index) => {
            return <option value={value}>{value}</option>;
          })}
      </Select>
    </Flex>
  );
};

export const dropdownCustomSvalue = ({
  data,
  mandatory,
  title,
  temparray,
  isDisabled,
  isLoading,
  onChange,
  placeholder,
}) => {
  // console.log('isLoading', isLoading);
  return (
    <Flex flexDirection={'column'}>
      <Flex flexDirection={'row'}>
        <Text
          color={theme.customColors.blackAdminColor['100']}
          fontSize="md"
          marginBottom={'2'}
        >
          {title}
        </Text>
        {mandatory ? (
          <Text color={'red'} marginLeft={'1'}>
            *
          </Text>
        ) : null}
      </Flex>
      {isLoading === true ? (
        <>
          <Select
            placeholder={placeholder}
            isDisabled={isDisabled}
            color={theme.customColors.blackAdminColor['100']}
            onChange={onChange}
          >
            {temparray &&
              temparray.map((value, index) => {
                // console.log('Checklist', value);
                return <option value={value.val}>{value.list}</option>;
              })}
          </Select>
        </>
      ) : isLoading === false ? (
        <Skeleton height={'40px'} />
      ) : (
        <>
          <Select
            placeholder={placeholder}
            isDisabled={isDisabled}
            color={theme.customColors.blackAdminColor['100']}
            onChange={onChange}
          >
            {temparray &&
              temparray.map((value, index) => {
                // console.log('Checklist', value);
                return <option value={value.val}>{value.list}</option>;
              })}
          </Select>
        </>
      )}
    </Flex>
  );
};
