import React, { useState, useEffect, useContext } from 'react';
import { colGameManagement, options } from '../../context/data';
import ReactPaginate from 'react-paginate';
import { useHistory } from 'react-router-dom';
import style from '../../components/common/style';
import styles from '../../components/common/style';
import LangContext from '../../context/languageContext';
import Loading from '../../components/common/Loading';
import { customInputLabel } from '../../components/createAppVersionComponent/createAppVersionComponent';
import {
  ArrowUpIcon,
  ArrowDownIcon,
  DeleteIcon,
  EditIcon,
  DragHandleIcon,
} from '@chakra-ui/icons';

import {
  Tr,
  Th,
  Td,
  Flex,
  Text,
  Table,
  Tbody,
  Select,
  Thead,
  Button,
  Spacer,
  Divider,
  useToast,
  Tooltip,
} from '@chakra-ui/react';
// import { TableComp } from '../components/common/table';
import { MdImportExport } from 'react-icons/md';
import ApiManager from '../../config/apiManager';
import { MiniHeader } from '../../components/common/customComponent';

// TODO //
// Set "NO Data" message when nothing find on search //

const FaqManagement = () => {
  let tempArray = [];

  let toast = useToast();
  let history = useHistory();
  const [page, setPage] = useState(1);
  const [error, setError] = useState(null);
  const [value, setValue] = useState('10');
  const [data, setData] = React.useState([]);
  let apiManager = ApiManager.getInstance();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = React.useState('');
  const [paginate, setpaginate] = React.useState();
  const [pageChange, setPageChange] = useState('');
  const { currentLangData } = useContext(LangContext);
  const [valueSearch, setValueSearch] = React.useState();
  const [selectvalue, setSelectvalue] = React.useState(10);
  const [currentPage, setCurrentPage] = React.useState(0);

  React.useEffect(() => {
    getAllCategory(page, value);
  }, []);

  // Utility functions /
  const handlePageClick = ({ selected: selectedPage }) => {
    console.log('next and previous', loading);
    setCurrentPage(selectedPage);
    getAllCategory(selectedPage + 1, value);
    console.log('clicked', selectedPage + 1);
  };

  const handleOnChange = (evt, key) => {
    const ev = evt.target.value;
    console.log('event', ev);
    setValue(ev);
    getAllCategory(page, ev);
  };

  let totalDatalength = paginate?.totalDocs;
  const PER_PAGE = selectvalue;
  const offset = currentPage * PER_PAGE;
  let pageCount = paginate?.totalPages;
  const pageCeilingCount = Math.ceil().paginate?.totalPages;
  console.log(pageCeilingCount, 'PAGE COUNT', pageCount);

  useEffect(() => {}, [page]);
  useEffect(() => {}, [value]);

  const navigateToEditPage = editData => {
    console.log('editData=>', editData);
    history.push({
      pathname: '/CreateMaintenance',
      state: { data: editData, useEditApi: true },
    });
  };

  // Api implementation //

  const deleteRecord = data => {
    console.log('deleteRecord', data);
    let body = {
      id: data?._id,
    };
    apiManager
      .post('deleteMaintenance', body)
      .then(res => {
        console.log('response=>', res);
        if (res.message === 8259) {
          setSuccess('Deleted');
          toast({
            title: success,
            status: 'success',
            duration: 2000,
            // isClosable: true,
            description: `Successfully Deleted record!`,
          });
          getAllCategory(1, value);
        } else {
          setError('Something went wrong');
          toast({
            title: error,
            duration: 2000,
            status: 'error',
            // isClosable: true,
            description: `Failed! Please try agian later!`,
          });
          getAllCategory(1, value);
        }
      })
      .catch(error => {
        console.log('error', error);
      });
  };

  const getAllCategory = (page, value) => {
    setLoading(true);
    let tempArray = [];
    console.log('page=>', page);
    let body = {
      page: page,
      // nextPage: 3,
      numRecords: value,
    };
    apiManager
      .get('getAllMaintenance', body)
      .then(res => {
        // console.log('getAllMaintenance=>RES', res);
        if (res.message === 8261) {
          // console.log('Get getAllMaintenance', res);
          pageCount = res?.record?.totalDocs;
          res?.record?.docs.map((value, index) => {
            console.log('valuesssss', value);
            tempArray.push(value);
          });
          setpaginate(res?.record);
          setData(tempArray);
          setLoading(false);
        } else {
          setError('Something went wrong');
          toast({
            title: error,
            duration: 2000,
            status: 'error',
            // isClosable: true,
            description: `${res.message} Failed! Please try agian later!`,
          });
        }
      })
      .catch(error => {
        setLoading(false);
        console.log('error', error);
      });
  };

  const handleOnSearch = (evt, key) => {
    const ev = evt.target.value;
    setValueSearch(ev);
    searchItems(ev, value, 1);
  };

  const searchItems = (value, limit, page) => {
    console.log('searchItems', value, limit, page);
    setLoading(true);
    const body = {
      searchPhrase: value,
      numRecords: limit,
      page: page,
    };
    console.log('body', body);
    apiManager
      .post('searchMaintenance', body)
      .then(res => {
        console.log('res=>', res);
        if (res.message === 8263) {
          res?.result?.docs.map((value, index) => {
            tempArray.push(value);
          });
          setData(tempArray);
          setpaginate(res?.result);
          setLoading(false);
        }
      })
      .catch(error => {
        console.log('error', error);
        setLoading(false);
      });
  };
  console.log('val===',data)
  return (
    <Flex flexDirection={'column'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'Maintenance List',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path2: 'App Setup',
        })}
      </Flex>

      <Flex {...style.box} background={'#fafdfb'} padding={10}>
        <Flex {...style.main} padding={'1%'}>
          <Flex {...style.buttoncontainer}>
            <Button
              {...style.createbutton}
              onClick={() => history.push('/CreateMaintenance')}
            >
              Create
            </Button>
          </Flex>

          <Flex {...style.optionFlex}>
            <Flex {...style.showentries} alignItems={'center'} fontSize={'sm'}>
              <Text>{currentLangData?.app?.show}</Text>
              <Select
                {...style.select}
                onChange={e => handleOnChange(e, 'numrecords')}
              >
                {options.map(option => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </Select>
              <Text>{currentLangData?.app?.entries}</Text>
            </Flex>
            <Spacer />
            <Flex alignItems={'center'} {...style.showentries}>
              <Text paddingX={'5px'}>{currentLangData?.app?.search}: </Text>
              {customInputLabel({
                size: 'sm',
                onChange: e => handleOnSearch(e),
              })}
            </Flex>
          </Flex>

          <Table {...styles.table} padding={'1%'}>
            <Thead {...styles.thead}>
              <Tr>
                <Th {...styles.th}>Platform</Th>
                <Th {...styles.th}>Start-Date</Th>
                <Th {...styles.th}>End-Date</Th>
                <Th {...styles.th}>Actions</Th>
              </Tr>
            </Thead>
            <Tbody {...styles.tbody}>
              {data?.length > 0 ? (
                !loading ? (
                  data &&
                  data?.map((value, index) => {
                    console.log(index, 'value', value);
                    let startD = new Date(value?.startDate * 1000);
                    let startFinalD = startD.toLocaleDateString();
                    let startFinalTime = startD.toLocaleTimeString();

                    let endD = new Date(value?.endDate * 1000);

                    let endFinalD = endD.toLocaleDateString();
                    let endFinalTime = endD.toLocaleTimeString();
                    if (index >= 0) {
                      return (
                        <>
                          <Tr key={index} {...styles.tr}>
                            <Td>
                              {value.platforms.length
                                ? value.platforms + ' ' : '- -'}
                            </Td>
                            <Td
                              flex={1}
                              justifyContent={'center'}
                              alignItems={'center'}
                            >
                              {startFinalTime + ' ' + startFinalD}
                            </Td>
                            <Td
                              flex={1}
                              justifyContent={'center'}
                              alignItems={'center'}
                            >
                              {endFinalTime + ' ' + endFinalD}
                            </Td>
                            <Td
                              width={'15%'}
                              flexDirection={'row'}
                              alignItems={'center'}
                              justifyContent={'flex-end'}
                            >
                              <Tooltip label={currentLangData?.app?.edit}>
                                <Button
                                  {...style.editbtn}
                                  leftIcon={<EditIcon />}
                                  onClick={() => navigateToEditPage(value)}
                                ></Button>
                              </Tooltip>
                              <Tooltip
                                label={currentLangData?.app?.delete}
                                bg={'red'}
                              >
                                <Button
                                  {...style.Delbtn}
                                  leftIcon={<DeleteIcon />}
                                  onClick={() => deleteRecord(value)}
                                ></Button>
                              </Tooltip>
                            </Td>
                          </Tr>
                        </>
                      );
                    } else {
                      return (
                        <Tr height={'40vh'}>
                          <Td></Td>
                          <Td>
                            <Text>NO DATA AVAILABLE</Text>
                          </Td>
                          <Td></Td>
                        </Tr>
                      );
                    }
                  })
                ) : (
                  <Tr>
                    {/* <Td></Td> */}
                    <Td></Td>
                    <Td paddingY={'15vh'}>
                      <Flex
                        style={{
                          w: '100%',
                          h: '100%',
                          d: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          top: '0',
                          left: '0',
                        }}
                      >
                        <Loading />
                      </Flex>
                    </Td>
                    <Td></Td>
                    {/* <Td></Td>  */}
                  </Tr>
                )
              ) : (
                <Tr>
                  <Td paddingY={'15vh'}>
                    <Flex {...style.elseflex}>
                      <Text fontSize={'xl'}>Oops! Data bucket is Empty</Text>
                    </Flex>
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
          <Divider
            color={'lightgray'}
            orientation="horizontal"
            marginTop={5}
            marginBottom={2}
          />

          <Flex marginTop={10} flexDirection={'column'}>
            <Text>
              Showing {data.length} to {PER_PAGE} of {totalDatalength} entries
            </Text>
            <Flex {...style.paginatecontainer}>
              <ReactPaginate
                nextLabel={'Next'}
                pageCount={pageCount}
                previousLabel={'Previous'}
                activeClassName={'active'}
                nextClassName={'page-item'}
                pageClassName={'page-item'}
                nextLinkClassName={'page-link'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                containerClassName={'pagination'}
                onPageChange={handlePageClick}
                previousLinkClassName={'page-link'}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
export default FaqManagement;
