import React from 'react';
import { Flex, Text, Divider } from '@chakra-ui/react';
import {
  tabViewAppUserReport,
  renderCopyRight,
} from '../../components/aboutUsComponent/customCompoent';
import styles from '../../styles/aboutUsStyles';
import { GraphTab } from './AppUserReportTabs/GraphTab';
import { ExportTab } from './AppUserReportTabs/ExportTab';
import { useHistory } from 'react-router-dom';
import { MiniHeader } from '../../components/common/customComponent';
import style from '../../components/common/style';

const AppUserReport = () => {
  console.log('AppUserReport gg bro');
  let history = useHistory();

  return (
    <Flex flexDirection={'column'} background={'#fafdfb'}>
      <Flex {...styles.miniheader}>
        {MiniHeader({
          title: 'App User Report',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path1: 'Reports',
          useSlash:'true',
          path2: 'AppUserReport',
        })}
      </Flex>
      <Flex flexDirection={'column'} {...style.box} height={'100vh'}>
      <Flex {...styles.btnFlex}  padding={'2%'}>
        {tabViewAppUserReport({
          tabP1: GraphTab(),
          tabP2: ExportTab(),
        })}
        
      </Flex>
      <Flex flexDirection={'column'}>
      <Divider maxW={'100%'} marginBottom={3} marginTop={"2%"} />
      {renderCopyRight()}
      </Flex>
      </Flex>

    </Flex>
  );
};
export default AppUserReport;
