import React from 'react';
import { Box, Button, Flex } from '@chakra-ui/react';
import style from '../../components/common/style';
import { TableComp } from '../../components/common/table';
import { listGameSettings, colGameSettings } from '../../context/data';
import { MiniHeader } from '../../components/common/customComponent';
import { SingleComponentCreateButtonTableHeader } from '../../components/common/SingleComponentCreateButtonTableHeader';

export const GameSettings = () => {
  console.log('GameManagement gg bro');
  return (
    <SingleComponentCreateButtonTableHeader
      MiniHeaderTitle={'Game Settings'}
      MiniHeaderPath={'Dashboard '}
      MiniHeaderPath1={' Reports '}
      MiniHeaderPath2={'GameSettings'}
      buttonName={'Create'}
      TableList={listGameSettings}
      TableColNames={colGameSettings}
      TableScreenName={'LuckDrawList'}
    />
  );
};
