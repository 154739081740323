import React, { useState } from 'react';
import {
  Flex,
  Box,
  Button,
  Text,
  Input,
  Spacer,
  Select,
  Grid,
} from '@chakra-ui/react';
import 'react-datepicker/dist/react-datepicker.css';
import '.././../styles/styles.css';
import InputwithTitle from '../../components/InputwithTitle/InputwithTitle';
import DateWithTitle from '../../components/DateComponent/DateWithTitle';
import style from './styles';
import DropDownWithTitle from '../../components/DateComponent/DropDownWithTitle';
import { MiniHeader } from '../../components/common/customComponent';
import { useHistory } from 'react-router-dom';
import { TableComp } from '../../components/common/table';
import {
  listPhysicalRedemptionReport,
  colPhysicalRedemptionReport,
} from '../../context/data';
const PhysicalRedemptionReport = () => {
  const [startDate, setStartDate] = useState(new Date());
  let history = useHistory();
  return (
    <Flex w={'100%'} flexDirection={'column'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'ADX Physical Redemption Report',
          path: 'Dashboard',
      onclickForRoute: () => history.push('/Dashboard'),
          path1: 'Reports',
          useSlash: true,
          path2: 'PhysicalRedemptionReport',
        })}
      </Flex>

      <Flex {...style.cardALContainer} background={'#fafdfb'} padding={'2%'}>
        <Flex {...style.innerCard}>
          <Flex {...style.datesContainer} padding={5}>
            <Flex flexDirection={'row'}>
              <DateWithTitle
                title={'Start Date'}
                style={style.dateComponentStyles}
              />
              <DateWithTitle
                title={'End Date'}
                style={style.dateComponentStyles}
              />
            </Flex>
            <Flex width={'50%'}>
              <InputwithTitle
                title={'MSISDN'}
                placeHolder={''}
                style={style.dateComponentStyles}
              />
            </Flex>
          </Flex>

          <TableComp
            list={listPhysicalRedemptionReport}
            colNames={colPhysicalRedemptionReport}
          />

          <Flex marginTop={6}>
            <Button {...style.downloadButton} border={0} ml={'5%'}>
              Download
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PhysicalRedemptionReport;
