import React from 'react';
import { Flex, Box, Button } from '@chakra-ui/react';
import 'react-datepicker/dist/react-datepicker.css';
import '.././../../styles/styles.css';
import DateWithTitle from '../../../components/DateComponent/DateWithTitle';
import style from './../styles';

export const ExportTab = () => {
  return (
    <Flex w={'100%'} flexDirection={'column'} bgColor={'yellow'}>
      {/* <Flex {...style.cardALContainer} bgColor={'yellow'}> */}
        <Flex {...style.innerCard}>
          <Flex {...style.datesContainer} >
            <DateWithTitle
              title={'Start Date'}
              style={style.dateComponentStyles}
            />
            <DateWithTitle
              title={'End Date'}
              style={style.dateComponentStyles} marginX={-2}
            />
          </Flex>
          <Button {...style.downloadButton} border={0} margin={'2%'}>
            Download
          </Button>
        </Flex>
      {/* </Flex> */}
    </Flex>
  );
};
