import React from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import style from '../components/common/style';
import { TableComp } from '../components/common/table';
import {
  listPhysicalRedemptionFormDetail,
  colPhysicalRedemptionFormDetail,
} from '../context/data';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { MiniHeader } from '../components/common/customComponent';

const PhysicalRedemptionFormDetail = () => {
  let history = useHistory();
  console.log('PhysicalRedemptionFormDetail gg bro');
  return (
    <Flex w={'100%'} flexDirection={'column'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'Physical redemption form details List',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path1:'Compaign Management',
          useSlash:true,
          path2:'Dynamic Compaign',
        })}
      </Flex>
      <Flex {...style.box} background={'#fafdfb'} padding={10}>
        <Flex {...style.main}>
          <Flex {...style.buttoncontainer}>
            <Button
              {...style.createbutton}
              onClick={() => history.push('/CreatePhysicalRedemptionForm')}
            >
              Create
            </Button>
          </Flex>
          <TableComp
            list={listPhysicalRedemptionFormDetail}
            screenName="LuckDrawList"
            colNames={colPhysicalRedemptionFormDetail}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
export default PhysicalRedemptionFormDetail;
