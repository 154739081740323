import React, { useState } from 'react';
import {
  Flex,
  Box,
  Button,
  Text,
  Input,
  Spacer,
  Select,
} from '@chakra-ui/react';
import 'react-datepicker/dist/react-datepicker.css';
import '.././../styles/styles.css';
import InputwithTitle from '../../components/InputwithTitle/InputwithTitle';
import DateWithTitle from '../../components/DateComponent/DateWithTitle';
import style from './styles';
import DropDownWithTitle from '../../components/DateComponent/DropDownWithTitle';
import { MiniHeader } from '../../components/common/customComponent';
import { TableComp } from '../../components/common/table';
import { listSoftPinsReport, colSoftPinsReport } from '../../context/data';
import { useHistory } from 'react-router-dom';
const SoftpinsReport = () => {
  let history = useHistory();
  const [startDate, setStartDate] = useState(new Date());
  return (
    <Flex w={'100%'} flexDirection={'column'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'Softpins Report',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path1: 'Reports',
          useSlash: true,
          path2: 'SoftpinsReport',
        })}
      </Flex>

      <Flex {...style.cardALContainer} background={'#fafdfb'} padding={'2%'}>
        <Flex {...style.innerCard}>
          <Flex {...style.datesContainer} padding={10} width={'80%'}>
            <DropDownWithTitle
              title={'Type'}
              style={style.dateComponentStyles}
            />
            <DateWithTitle
              title={'Start Date'}
              style={style.dateComponentStyles}
            />
            <DateWithTitle
              title={'End Date'}
              style={style.dateComponentStyles}
            />
          </Flex>

          <Flex {...style.datesContainerWithoutPadding} paddingX={10} width={'100%'}>
            <InputwithTitle
              title={'MSISDN'}
              placeHolder={''}
              style={style.dateComponentStyles}
            />
            <InputwithTitle
              title={'Serial Number'}
              placeHolder={''}
              style={style.dateComponentStyles}
            />
          </Flex>

          <TableComp list={listSoftPinsReport} colNames={colSoftPinsReport} />

          <Flex marginTop={6}>
            <Button {...style.downloadButton} border={0} ml={'5%'}>
              Download
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SoftpinsReport;
