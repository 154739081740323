import React from 'react';
import { Box, Button, Flex, Text, Stack, Select } from '@chakra-ui/react';
import style from '../../components/common/style';
import { TableComp } from '../../components/common/table';
import { listLuckyDrawUpsell, colLuckyDrawUpsell } from '../../context/data';
import DateWithTitle from '../../components/DateComponent/DateWithTitle';
import { MiniHeader } from '../../components/common/customComponent';
import { useHistory } from 'react-router-dom';

const LuckyDrawUpsell = () => {
  let history = useHistory();
  return (
    <Flex flexDirection={'column'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'Luck Draw list',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path2: 'Luck Draw Upsell',
        })}
      </Flex>
      <Flex {...style.box} padding={10}>
        <Flex {...style.main}>
          <Flex {...style.buttoncontainer}></Flex>
          <Flex
            alignItems={'center'}
            fontSize={'12'}
            justifyContent={'space-between'}
            marginX={'2%'}
            marginY={'1%'}
          >
            <Text marginX={'2%'}>Compaign Name</Text>
            <Select placeholder="--Select" width={'70%'}>
              <option value="open">Lucky Draw Iphone</option>
              <option value="close">Want To Join Our Lucky Draw</option>
              <option value="close">Test Up</option>
            </Select>
          </Flex>
          <Flex
            // {...style.datepickers}
            fontSize={'12'}
            justifyContent={'center'}
            marginY={'2%'}
            marginX={'2%'}
          >
            <Flex flex={1} alignItems={"center"}>
              <Text fontSize={"xs"} >Start Date</Text>
              <DateWithTitle title={''} mandatory={true} style={style.dateComponentStyles} marginX={0} />
            </Flex>

            <Flex alignItems={"center"}flex={1}>
              <Text fontSize={"xs"} >End Date</Text>
              <DateWithTitle title={''} mandatory={true} style={style.dateComponentStyles} />
            </Flex>
            <Flex alignItems={'center'} flex={1}>
              <Text fontSize={"xs"}>Status</Text>
              <Select placeholder="--Select" marginTop={'1%'}>
                <option value="open">Open</option>
                <option value="close">Close</option>
              </Select>
            </Flex>
          </Flex>
    
          <Flex paddingX={'6%'}>
            <Stack direction="row" spacing={8} align="center">
              <Button bgColor={'#000000'} {...style.Upsellbtn}>
                Search
              </Button>
              <Button bgColor={'#cdd3d8'} {...style.UpsellExportbtn}>
                Export
              </Button>
              <Button bgColor={'#FC544B'} {...style.UpsellChangebtn}>
                Change Status
              </Button>
            </Stack>
          </Flex>
          <TableComp
            list={listLuckyDrawUpsell}
            screenName="LuckDrawList"
            colNames={colLuckyDrawUpsell}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default LuckyDrawUpsell;
