import React from 'react';
import ReactPaginate from 'react-paginate';
import { CheckIcon, UpDownIcon } from '@chakra-ui/icons';
import { MdImportExport } from 'react-icons/md';
import { options } from '../../context/data';
import LangContext from '../../context/languageContext';
import {
  Box,
  Button,
  Flex,
  Text,
  Select,
  Spacer,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Icon,
  Circle,
} from '@chakra-ui/react';
import style from '../common/style';
import Loading from './Loading';
import { onDelete } from 'draft-js/lib/RichTextEditorUtil';
export const Checktable = ({ datalist, screenType, headerNames, dataState,screenName,onDelete}) => {
  const [currentPage, setCurrentPage] = React.useState(0);
  const [selectvalue, setSelectvalue] = React.useState(10);
  const [EditDelButton, setEditDelButton] = React.useState(false);
  const [viewdownloadbutton, setviewdownloadbutton] = React.useState(false);
  const { currentLangData } = React.useContext(LangContext);

  const handlePageClick = ({ selected: selectedPage }) => {
    console.log('clicked', selectedPage);
    setCurrentPage(selectedPage);
  };
  // console.log('datalist>>>>>>>', datalist);
  let totalDatalength = datalist?.length;
  console.log('list', datalist?.length);
  const PER_PAGE = selectvalue;
  console.log('No of data show in a single page>>', PER_PAGE);
  const offset = currentPage * PER_PAGE;
  console.log('offset', offset);
  const pageCount = Math.ceil(totalDatalength / PER_PAGE);

  React.useEffect(() => {
    console.log('Check props', screenType);
    console.log('first',screenName)
    if (screenType === 'buttons') {
      setEditDelButton(true);
    } else if (screenType === 'LuckDrawList') {
      setviewdownloadbutton(true);
    }
  }, []);
  return (
    <Flex width={'100%'} flexDirection={'column'} paddingX={5}>
      <Flex {...style.optionFlex}>
        <Flex {...style.showentries} alignItems={'center'} fontSize={'sm'}>
          <Text>{currentLangData?.app?.show}</Text>
          <Select
            {...style.select}
            onChange={e => setSelectvalue(e.target.value)}
          >
            {options.map(option => (
              <option value={option.value}>{option.label}</option>
            ))}
          </Select>
          <Text>{currentLangData?.app?.entries}</Text>
        </Flex>
        <Spacer />
        <Flex alignItems={'center'} {...style.showentries}>
          <Text paddingY={'5px'}>{currentLangData?.app?.search}:</Text>
          <Input {...style.searchinput}></Input>
        </Flex>
      </Flex>
      <Flex {...style.tableFlex}>
        <Table {...style.table}>
          <Thead {...style.thead}>
            <Tr>
              {headerNames.map((headerItem, index) => (
                <Th {...style.th} key={index}>
                  <Flex>
                    <MdImportExport />
                    {headerItem}
                  </Flex>
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody {...style.tbody}>
            {datalist.length > 0 ? (
              datalist.map((value, index) => {
                // console.log('>>>>>>>>>>>>>>>>', value?.isoCode);
                let date = new Date(value.createdAt / 1000);
                // console.log('datalist length>>>', datalist.length);
                return (
                  <Tr key={index} width={'100%'}>
                    <Td>{value?.isoCode}</Td>
                    <Td>{value?.name}</Td>
                    {EditDelButton ? (
                      <Td
                        position={'relative'}
                        display={'inline-flex'}
                        verticalAlign={'middle'}

                      >
                        <Button {...style.editbtn}>
                          {currentLangData?.app?.edit}
                        </Button>
                        <Button {...style.Delbtn}>
                          {currentLangData?.app?.delete}
                        </Button>
                      </Td>
                    ) : viewdownloadbutton ? (
                      <Td>
                        <Button {...style.editbtn}>
                          {currentLangData?.app?.view}
                        </Button>
                        <Button {...style.Delbtn}
                       onClick={()=> onDelete(value)}
                        >
                          {currentLangData?.app?.download}
                        </Button>
                      </Td>
                    ) : null}
                  </Tr>
                );
              })
            ) : (
              <Tr>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td paddingY={'15vh'}>
                  <Flex justifyContent={'flex-start'}>
                    <Loading />
                  </Flex>
                </Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Flex>
      <Flex {...style.tablefooter}>
        <Flex>
          <Text>
            {currentLangData?.app?.showing} {offset} {currentLangData?.app?.to}{' '}
            {PER_PAGE} {currentLangData?.app?.of} {totalDatalength}{' '}
            {currentLangData?.app?.entries}
          </Text>
        </Flex>
        <Flex {...style.paginatecontainer}>
          <ReactPaginate
            previousLabel={'Previous'}
            nextLabel={'Next'}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}
            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}
            activeClassName={'active'}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
