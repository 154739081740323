import Config from './config';
import CryptoJS from 'crypto-js';

// TODO //
// Delete Images from s3 bucket //

export const Api_name = {
  login: 'login',
  getUser: 'getUser',
  createSession: 'createSession',
  updateSession: 'updateSession',
  updateMerchant: 'updateMerchant',
  getAllMerchants: 'getAllMerchants',
  registerMerchant: 'registerMerchant',
  getAllBannerTypes: 'getAllBannerTypes',
};
let ssoApiAdvanceList = {
  sendNotificationToAllUsers: 1,
  sendNotificationToBulkUsers:1,
  getNotificationTopics:1,
};
let ssoApiAdvancePaymentGateway = {
  getPaymentGateways: 1,
  getAllMerchantCodes: 1,
  selectPaymentGateway: 1,
  addMerchantCodes: 1,
  getGatewayActions: 1,
  transactionReport: 1,
  // getAllActions:1,
  assignMerchantCodesToAction: 1,
  revenueReport: 1,
  totalTransactionReport: 1,
  uniqueUserReport: 1,
  blackMarketReport: 1,
  paymentChannelsReport: 1,
};
let blackMarketApilist = {
  get_latest_products: 1,
  search_products: 1,
  get_product_detail: 1,
  get_promotions_for_admin: 1,
};

let ssoApiList = {
  login: 1,
  getAllOtpLogs: 1,
  facebookLogin: 1,
  googleLogin: 1,
  changeCMSUserRole:1,
  getAllUsers: 1,
  getCMSUser: 1,
  getUserDetailsForCMS: 1,
  updateUserFromCMS: 1,
  updateRoleName: 1,
  loginCMSUser: 1,
  getAllCMSRoles: 1,
  unsuspendUser: 1,
  getAllActions: 1,
  suspendCMSUser:1,
  unsuspendCMSUser:1,
  deleteUserFromCMS: 1,
  getCmsSuspendedUsers:1,
  getAllCMSUsers: 1,
  addCMSUser: 1,
  searchOtpWithMSISDN: 1,
  editBlackUserProfile: 1,
  searchBlackUserProfile: 1,
  registerCMSUser: 1,
  suspendUser: 1,
  unSuspendUser: 1,
  resetPasswordFromAdmin: 1,
  verifyUserEmail: 1,
  login: 1,
  getRole: 1,
  changePasswordSuperUser: 1,
  updateUserPasswordFromCMS: 1,
  changeCMSUserPassword: 1,
  getSuspendedUsers: 1,
  getAllDeletedUsers: 1,
  restoreDeletedUser: 1,
  createRole: 1,
  removeActions: 1,
  addActions: 1,
  deleteSelfcareProfile: 1,
  suspendRole: 1,
  unsuspendRole: 1,
  getAllSuspendedRoles: 1,
};

let middlewareApilist = {
  searchOtpWithMSISDN: 1,
  getAllOtpLogs: 1,
};

const MDG = 'mdg';
export default class ApiManager {
  static POST = 'POST';
  static GET = 'GET';
  static DELETE = 'DELETE';
  static PUT = 'PUT';
  static singleton = null;

  trax_session_id = null;
  token = null;

  //returns instance of this class
  static getInstance() {
    if (ApiManager.singleton == null) {
      ApiManager.singleton = new ApiManager();
    }
    return ApiManager.singleton;
  }

  //this will create a session in ApiManager
  createSession(fcmKey, callback = this.defaultCallback) {
    //call createSession api here to set the mdg sessionid
    this.put('createSession', { fcmKey })
      .then(response => {
        this._setSessionID(response.session.sessionId);
        callback(true);
      })
      .catch(error => {
        console.log(error);
        callback(false);
      });
  }

  //this will update session in ApiManager
  updateSession(fcmSessionBody, callback = this.defaultCallback) {
    // console.log('update session params => ', fcmSessionBody);
    //call updateSession api here to uodate the mdg sessionid
    this.post('updateSession', fcmSessionBody)
      .then(response => {
        // console.log('update session response => ', response);
        this._setSessionID(response.session.sessionId);
        callback(response);
      })
      .catch(error => {
        // console.log('error while updating session => ', error);
        callback(false);
      });
  }

  //this will get notification count
  getNotificationCount(callback = this.defaultCallback) {
    this.get('getNotificationsCount', {})
      .then(response => {
        callback(response);
      })
      .catch(_error => {
        callback(false);
      });
  }

  //this is a default callback. no need to add anything in this. can be used for testing purpose
  defaultCallback = response => {};

  API_WO_LOGIN = [
    'getAllPosts',
    'getDrops',
    'getEcommercePosts',
    'getChannelPosts',
    'getPostComments',
    'getCommentReplies',
    'get_product_detail',
    'get_trending_products',
    'get_latest_products',
    'get__merchant_products',
    'getHashTagPosts',
    'getPersonFollowers',
    'getPersonFollowing',
    'getPostDetails',
    'getPersonChannel',
    'getTaggedPosts',
    'getLikedPosts',
  ];

  CORRESPONDING_API_W_LOGIN = [
    'getUserAllPosts',
    'getUserDrops',
    'getUserEcommercePosts',
    'getUserChannelPosts',
    'getUserPostComments',
    'getUserCommentReplies',
    'get_user_product',
    'get_user_trending_products',
    'get_user_latest_products',
    'get_user_merchant_products',
    'getUserHashTagPosts',
    'getUserPersonFollowers',
    'getUserPersonFollowing',
    'getUserPostDetails',
    'getUserPersonChannel',
    'getUserTaggedPosts',
    'getUserLikedPosts',
  ];

  BEFORE_LOGIN = [
    'login',
    'facebookLogin',
    'googleLogin',
    'updateSession',
    'getReportReasons',
    'createSession',
  ];

  //Use this function to make post calls to the server
  post = (endpoint, body, type = MDG) => {
    return new Promise((resolve, reject) => {
      let urls = this.generateEndpointUrl(endpoint, type);
      let endpointUrl = urls.fullUrl;
      let queryParam = body.queryParam;
      if (queryParam) {
        delete body.queryParam;
        let strQp = '?';
        Object.keys(queryParam).map((qp, i, arr) => {
          if(arr.length - 1 !== i) {
            strQp += `${qp}=${queryParam[qp]}&`;
          } else {
            strQp += `${qp}=${queryParam[qp]}`;
          }
        })
        endpointUrl += strQp;
      }
      // console.log('endpoint', endpointUrl);
      this._callServer(endpointUrl, body, ApiManager.POST, urls.endpoint)
        .then(json => {
          resolve(json);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  //Use this function to make get calls to the server
  get = (endpoint, params, type = MDG) => {
    return new Promise((resolve, reject) => {
      let urls = this.generateEndpointUrl(endpoint, type);
      let endpointUrl = urls.fullUrl + this.convertToGetParams(params);
      this._callServer(endpointUrl, null, ApiManager.GET, urls.endpoint)
        .then(json => {
          resolve(json);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  //Use this function to make get calls to the server
  getDrops = (endpoint, params, type = MDG) => {
    return new Promise((resolve, reject) => {
      let endpointUrl = this.generateEndpointUrl(endpoint, type).fullUrl;
      this._callServer(endpointUrl, null, ApiManager.GET)
        .then(json => {
          resolve(json);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  //Use this function to make put calls to the server
  put = (endpoint, body, type = MDG) => {
    return new Promise((resolve, reject) => {
      let endpointUrl = this.generateEndpointUrl(endpoint, type).fullUrl;
      this._callServer(endpointUrl, body, ApiManager.PUT)
        .then(json => {
          resolve(json);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  //Use this function to make delete calls to the server
  delete = (endpoint, body, type = MDG) => {
    return new Promise((resolve, reject) => {
      let endpointUrl = this.generateEndpointUrl(endpoint, type).fullUrl;
      this._callServer(endpointUrl, body, ApiManager.DELETE)
        .then(json => {
          resolve(json);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  // ssoApiList = ()  => {
  //   let apiList = {
  //     'getAllUsers' : 1,
  //     'getAllOtpLogs' : 1,
  //   }
  //   return apiList
  // };

  //generate the url for the specific endpoint
  generateEndpointUrl = (endpoint, type) => {
    //Check if user logged in
    // this logic is for when endpoint is getHastTag/hashTag
    let words = endpoint.split('/');
    endpoint = words[0];
    if (
      this.BEFORE_LOGIN.includes(endpoint) === false &&
      this.API_WO_LOGIN.includes(endpoint) === true
    ) {
      endpoint = this._checkLoginStatus(endpoint);
      if (words.length >= 2) {
        let after = endpoint.split('?');
        let params = '';
        words.forEach((w, i) => {
          if (i === 0) params = endpoint + '/';
          else params += w + '/';
        });
        endpoint =
          params.substr(0, params.length - 1) +
          (after[1] ? '?' + after[1] : '');
      }
    } else if (words.length >= 2) {
      let params = '';
      words.forEach((w, i) => {
        if (i === 0) params = endpoint + '/';
        else params += w + '/';
      });
      endpoint = params.substr(0, params.length - 1);
    }

    if (endpoint in middlewareApilist) {
      //will return the fullUrl of Otp api before the sso
      return {
        fullUrl: process.env.REACT_APP_MIDDLEWARE_API + endpoint,
        endpoint: words[0],
      };
    }
    
    // console.log('ssoAPis', ssoApiList);
    // console.log('endPoint', endpoint);
    if (endpoint in ssoApiList) {
      // console.log('endpoint in ssoApiList', endpoint in ssoApiList);
      // console.log(
      //   'Config',
      //   process.env.REACT_APP_BLACK_API_URL
      // );
      return {
        fullUrl: process.env.REACT_APP_SSO_BASE_URL + endpoint,
        endpoint: words[0],
      };
    }
    if (endpoint in ssoApiAdvanceList) {
      return {
        fullUrl: process.env.REACT_APP_SSO_ADVANCE_URL + endpoint,
        endpoint: words[0],
      };
    } else if (endpoint in blackMarketApilist) {
      // console.log('BlackMarketAPi-Url>>',process.env.REACT_APP_BLACK_MARKET_API + endpoint);
      return {
        fullUrl: process.env.REACT_APP_BLACK_MARKET_API + endpoint,
        endpoint: words[0],
      };
    } else if (endpoint in ssoApiAdvancePaymentGateway) {
      if (endpoint === 'getGatewayActions') {
        endpoint = 'getAllActions';
        // console.log("else if endpoint",endpoint)
      }
      // console.log('running ssoPayment apis');
      return {
        fullUrl: process.env.REACT_APP_SSO_ADVANCE_PAYMENT + endpoint,
        endpoint: words[0],
      };
    } else {
      return {
        fullUrl: process.env.REACT_APP_BLACK_API_URL + endpoint,
        endpoint: words[0],
      };
    }

    // switch (type) {
    //   case MDG:
    //     return Config.black_api_url + endpoint;
    //   default:
    //     return Config.black_api_url + endpoint;
    //   // case CHAT:
    //   // return Config.chat_api_url + endpoint;
    // }
  };

  //convert object to GET params
  convertToGetParams = params => {
    let getParams = '';
    for (var key in params) {
      if (getParams !== '') {
        getParams += '&';
      }
      getParams += key + '=' + params[key];
    }
    return '?' + getParams;
  };

  getAuthorization = () => {
    let parseSavedUser = localStorage.getItem('user');
    // console.log('parseSavedUser - ', parseSavedUser);
    parseSavedUser = JSON.parse(parseSavedUser);
    // console.log('parsedSavedUser>>>>',parseSavedUser)
    // console.log('userId - ', parseSavedUser?.loginEmail)
    let rawStr = parseSavedUser?.loginEmail + ':' + parseSavedUser?.token;
    let wordArray = CryptoJS.enc.Utf8.parse(rawStr);
    let base64 = CryptoJS.enc.Base64.stringify(wordArray);
    return base64;
  };
  //Make the actual call to server here. this should not be called from outside this class
  _callServer = async (
    endpoint,
    body = null,
    method = ApiManager.GET,
    apiName
  ) => {
    let parseSavedUser = await localStorage.getItem('user');
    // console.log('parseSavedUser - ', parseSavedUser);
    parseSavedUser = JSON.parse(parseSavedUser);
    // console.log('parsedSavedUser>>>>',parseSavedUser)
    // console.log('userId - ', parseSavedUser?.loginEmail)
    let rawStr = parseSavedUser?.loginEmail + ':' + parseSavedUser?.token;
    let wordArray = CryptoJS.enc.Utf8.parse(rawStr);
    let base64 = CryptoJS.enc.Base64.stringify(wordArray);
    // console.log('Endpoint: ', endpoint);
    //check if this api is called before login and is one of api with/without login

    return new Promise((resolve, reject) => {
      let headers = {};

      //if token is present then mdg_session_id will be there already
      if (base64) {
        console.log('inBase64');
        headers = {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Basic ' + base64,
          'x-access-token': '' + base64,
          'trax-session-id': '' + this.trax_session_id,
          // clientid:"a9zPpbltfVC5yE",
          // clientsecret:"n1fTuiEKRPSEk1iTjOZLN056mB58ZGamoG3CUs2UTy6DMEOKqdZpYuKyZNvmnBmsj"
        };
        //if token is not present but mdg_session_id is present
        // console.log("THis.token",this.token)
        // console.log("Headersss",headers)
      } else if (this.trax_session_id) {
        console.log('trax_session_id');
        headers = {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Basic ' + this.token,
          'trax-session-id': '' + this.trax_session_id,
        };

        // console.log("THis.token",this.token)
        // console.log("Headersss",headers)
        //if there is no token and no mdg_session_id
      } else {
        console.log('elsePart');
        headers = {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        };
        // console.log("THis.token",this.token)
        // console.log("Headersss",headers)
      }

      if (ssoApiAdvancePaymentGateway[apiName]) {
        console.log('<-GateWayAPis->');
        headers.clientid = process.env.REACT_APP_CLIENTID;
        headers.clientsecret = process.env.REACT_APP_CLIENTSECRET;
      }
      if (blackMarketApilist[apiName]) {
        console.log('<-BlackMarketAPis->');
        headers.clientid = process.env.REACT_APP_CLIENTID;
        headers.clientsecret = process.env.REACT_APP_CLIENTSECRET;
      }

      fetch(endpoint, {
        // mode: 'no-cors',
        // credentials: 'include',
        method: method,
        headers: headers,
        body: body !== null ? JSON.stringify(body) : null,
      })
        .then(response => response.json())
        .then(json => {
          // console.log('json in fetch: ', endpoint, json);
          if (json.message === 5006) {
            console.log('<<<<<<<<<<User logout scene>>>>>>>>>>>');

            localStorage.removeItem('currentUser');
            localStorage.removeItem('user');
            window.location.href = '/Login';
          }
          if (json.message === 4000){
            window.location.href = '/AccessDenied'
            console.log('PageNotFound')
          }
          if (
            json.message === '1003' ||
            json.message === '1004' ||
            json.message === 5014 ||
            json.message === 5067
          ) {
            reject(json.message);
          } else resolve(json);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  //sets the session id in the session
  _setSessionID = sessionID => {
    if (window !== undefined) {
      localStorage.setItem('mdgSession', sessionID);
      // console.log('sessionID', sessionID);
      this.trax_session_id = sessionID;
    }
  };

  //gets the session id in the session
  _getSessionID = () => {
    return this.trax_session_id;
  };

  //sets the token in the session
  _setToken = (email, token) => {
    // console.log('token', token);
    // console.log('email', email);
    let rawStr = email + ':' + token;
    let wordArray = CryptoJS.enc.Utf8.parse(rawStr);
    let base64 = CryptoJS.enc.Base64.stringify(wordArray);
    this.token = base64;
  };

  // setting token without encoded
  _setActualToken = token => (this.token = token);

  //return the token in the session
  _getToken = () => {
    return this.token;
  };

  //checks if user is loggedin and returns corresponding API
  _checkLoginStatus = apiName => {
    var params = '';
    var api = apiName.split('?')[0];

    if (apiName.split('?')[1]) params = '?' + apiName.split('?')[1];

    if (this.token) {
      if (this.API_WO_LOGIN.indexOf(api) === -1) {
        return api + params;
      } else {
        return (
          this.CORRESPONDING_API_W_LOGIN[this.API_WO_LOGIN.indexOf(api)] +
          params
        );
      }
    } else {
      return api + params;
    }
  };
}
