// import phaseAction from './phaseaction'
// import clientAction from './clientAction'
import pageAction from './pageAction'
// import productsAction from './productsAction'
// import IndustryAction from './industryaction'
// import packedIndAction from './packedIndAction'
// import merchantsAction from './merchantsAction'
// import subscriptionaction from './subscriptionaction'
// import manufacturedaction from './manufacturedaction'
// import notificationAction from './notificationAction'

const allActions = {
    pageAction,
    // phaseAction,
    // clientAction,
    // productsAction,
    // IndustryAction,
    // packedIndAction,
    // merchantsAction,
    // notificationAction,
    // subscriptionaction,
    // manufacturedaction,
    
}

export default allActions