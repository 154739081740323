import React, { useState } from 'react';
import {
  Text,
  Flex,
  Input,
  Button,
  Switch,
  useToast,
  FormLabel,
  SimpleGrid,
  FormControl,
  InputGroup,
  Image,
  InputRightElement,
  Icon,
} from '@chakra-ui/react';
// import { BsFillFileEarmarkArrowUpFill } from 'react-icons/bs';
import { PhoneIcon, AddIcon, WarningIcon } from '@chakra-ui/icons'
import { AiOutlineFileDone } from "react-icons/ai";
import theme from '../../config/color';
import styles from './styles';
import LangContext from '../../context/languageContext';
import { size } from 'draft-js/lib/DefaultDraftBlockRenderMap';

export const customInputLabel = ({
  title,
  mandatory,
  type,
  style,
  pattern,
  variant,
  onChange,
  value,
  onKeyDown,
  size,
  defaultValue,
  disabled,
  accept,
  placeholder,
}) => {
  return (
    <Flex flexDirection={'column'}>
      <Flex flexDirection={'row'}>
        <Text
          color={theme.customColors.blackAdminColor['100']}
          fontSize="md"
          marginBottom={'2'}
        >
          {title}
        </Text>
        {mandatory ? (
          <Text color={'red'} marginLeft={'1'}>
            *
          </Text>
        ) : null}
      </Flex>
      <Input
        width={'100%'}
        size={size}
        style={style}
        variant={variant}
        type={type}
        onKeyDown={onKeyDown}
        pattern={pattern}
        accept={accept}
        onChange={onChange}
        value={value}
        defaultValue={defaultValue}
        isDisabled={disabled}
        placeholder={placeholder}
      />
    </Flex>
  );
};
export const customFileInputLabel = ({
  title,
  mandatory,
  uploadedProp,
  uploadedFileName,
  onClickButton,
  type,
  style,
  pattern,
  variant,
  onChange,
  value,
  onKeyDown,
  size,
  defaultValue,
  disabled,
  accept,
  placeholder,
}) => {
  console.log('uploadedProp', uploadedProp);
  return (
    <Flex flexDirection={'column'}>
      <Flex flexDirection={'row'}>
        <Text
          color={theme.customColors.blackAdminColor['100']}
          fontSize="md"
          marginBottom={'2'}
        >
          {title}
        </Text>
        {mandatory ? (
          <Text color={'red'} marginLeft={'1'}>
            *
          </Text>
        ) : null}
      </Flex>
      {uploadedProp === true ? (
        <Flex flexDirection={'column'} alignItems={'center'} mb={5}>
          <Icon as={AiOutlineFileDone} w={12} h={12} color={'green'}/>
          <Text my={2}>{uploadedFileName}</Text>
          {/* <Button onClick={onClickButton}><Text color={'red'}>Remove File</Text></Button> */}
        </Flex>
      ) : null }
        <Input
          width={'100%'}
          size={size}
          style={style}
          type={type}
          pattern={pattern}
          accept={accept}
          onChange={onChange}
          value={value}
          defaultValue={defaultValue}
          isDisabled={disabled}
          placeholder={placeholder}
        />
    </Flex>
  );
};
export const customInputLabelButton = ({
  title,
  mandatory,
  type,
  pattern,
  variant,
  onChange,
  value,
  onKeyDown,
  size,
  defaultValue,
  disabled,
  accept,
  placeholder,
  onClickSearch,
  isLoading,
}) => {
  return (
    <Flex flexDirection={'column'}>
      <Flex flexDirection={'row'}>
        <Text
          color={theme.customColors.blackAdminColor['100']}
          fontSize="md"
          marginBottom={'2'}
        >
          {title}
        </Text>
        {mandatory ? (
          <Text color={'red'} marginLeft={'1'}>
            *
          </Text>
        ) : null}
      </Flex>
      <InputGroup>
        <Input
          width={'100%'}
          size={size}
          variant={variant}
          type={type}
          onKeyDown={onKeyDown}
          pattern={pattern}
          accept={accept}
          onChange={onChange}
          value={value}
          defaultValue={defaultValue}
          isDisabled={disabled}
          placeholder={placeholder}
        />
        <InputRightElement width="4.5rem">
          <Button
            // h='1.75rem'
            size="sm"
            backgroundColor={'black'}
            height={'35px'}
            // width={'10%'}
            color={'white'}
            marginRight={1}
            isLoading={isLoading}
            boxShadow={
              '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
            }
            _hover={{
              bg: 'white.500',
              background: 'gray',
              transform: 'translateY(-2px)',
              // boxShadow: 'lg',
            }}
            _focus={{
              bg: 'gray',
            }}
            onClick={onClickSearch}
          >
            Search
          </Button>
        </InputRightElement>
      </InputGroup>
    </Flex>
  );
};

export const customInputImage = ({
  title,
  imageColor,
  mandatory,
  type,
  src,
  variant,
  onChange,
  onClick,
  value,
  size,
  disabled,
  alt,
  titleImage,
  placeholder,
}) => {
  return (
    <Flex flexDirection={'column'}>
      <Flex flexDirection={'row'}>
        <Text
          color={theme.customColors.blackAdminColor['100']}
          fontSize="md"
          marginBottom={'2'}
        >
          {title}
        </Text>
        {mandatory ? (
          <Text color={'red'} marginLeft={'1'}>
            *
          </Text>
        ) : null}
      </Flex>
      <InputGroup>
        <Input
          width={'100%'}
          size={size}
          variant={variant}
          color={'transparent'}
          type={type}
          onChange={onChange}
          title={titleImage}
          value={value}
          isDisabled={disabled}
          border={'none'}
          alt={alt}
          placeholder={placeholder}
        />
        <InputRightElement
          children={
            <Image
              borderRadius="full"
              // boxSize="40px"
              color={'transparent'}
              src={src}
              onClick={onClick}
              fallbackSrc="https://via.placeholder.com/150"
            ></Image>
          }
        />
      </InputGroup>
    </Flex>
  );
};

export const customInputImage2 = ({
  title,
  imageColor,
  mandatory,
  type,
  src,
  variant,
  onChange,
  onClick,
  value,
  size,
  disabled,
  alt,
  titleImage,
  placeholder,
}) => {
  return (
    <Flex flexDirection={'column'}>
      <Flex flexDirection={'row'}>
        <Text
          color={theme.customColors.blackAdminColor['100']}
          fontSize="md"
          marginBottom={'2'}
        >
          {title}
        </Text>
        {mandatory ? (
          <Text color={'red'} marginLeft={'1'}>
            *
          </Text>
        ) : null}
      </Flex>
      <InputGroup>
        <Input
          width={'100%'}
          size={size}
          variant={variant}
          color={'transparent'}
          type={type}
          onChange={onChange}
          title={titleImage}
          value={value}
          isDisabled={disabled}
          border={'none'}
          alt={alt}
          placeholder={placeholder}
        />
        <InputRightElement width="6.5rem"
          children={
            <Image
              color={'transparent'}
              src={src}
              onClick={onClick}
              fallbackSrc="https://via.placeholder.com/150"
            ></Image>
          }
        />
      </InputGroup>
    </Flex>
  );
};

export const customSwtich = ({
  label,
  title,
  noLabel,
  check,
  onchange,
  checked,
  disabled,
}) => {
  // const [swtichValue, setSwtichValue] = useState(false);
  return (
    <Flex flexDirection={'column'}>
      {!noLabel ? (
        <Text {...styles.smallHeading}>{label}</Text>
      ) : (
        <Text
          {...styles.smallHeading}
          color={'transparent'}
          // marginBottom={2}
        >
          {label}
        </Text>
      )}

      <FormControl
        size="sm"
        display="flex"
        fontSize={'sm'}
        marginRight={3}
        alignItems="center"
        onChange={check}
        color={theme.customColors.blackAdminColor['defaultFontColor']}
      >
        <FormLabel htmlFor="email-alerts" mb="0" fontSize={'sm'}>
          {title}
        </FormLabel>
        <Switch
          size="sm"
          id="email-alerts"
          checked={Boolean}
          isDisabled={disabled}
          onChange={onchange}
          isChecked={checked}
        />{' '}
      </FormControl>
    </Flex>
  );
};
export const UstomSwtich = ({ label, title, noLabel }) => {
  const [swtichValue, setSwtichValue] = useState(false);
  return (
    <Flex flexDirection={'column'}>
      {!noLabel ? (
        <Text {...styles.smallHeading}>{label}</Text>
      ) : (
        <Text
          {...styles.smallHeading}
          color={'transparent'}
          // marginBottom={2}
        >
          {label}
        </Text>
      )}

      <FormControl
        size="sm"
        display="flex"
        fontSize={'sm'}
        marginRight={3}
        alignItems="center"
        onChange={e => console.log('onChange of Switch', e.target.value)}
        color={theme.customColors.blackAdminColor['defaultFontColor']}
      >
        <FormLabel htmlFor="email-alerts" mb="0" fontSize={'sm'}>
          {title}
        </FormLabel>
        <Switch
          size="sm"
          id="email-alerts"
          // onChange={() => setSwtichValue(swtichValue)}
        />
      </FormControl>
    </Flex>
  );
};

export const customUpdateCancelBtn = ({
  title1,
  title2,
  isLoading,
  onClickCancel,
  onClickSubmit,
}) => {
  return (
    <Flex alignSelf={'flex-start'} width={'100%'} marginTop={5}>
      <Button
        // boxShadow={'2xl'}
        backgroundColor={'black'}
        size={'sm'}
        height={'35px'}
        // width={'8%'}
        color={'white'}
        marginRight={2}
        isLoading={isLoading}
        boxShadow={
          '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.19)'
        }
        _hover={{
          bg: 'white.500',
          //background: 'gray',
          //transform: 'translateY(-2px)',
          // boxShadow: 'lg',
        }}
        _focus={{
          bg: 'gray',
        }}
        onClick={onClickSubmit}
      >
        <Text size={'sm'}>{title1}</Text>
      </Button>
      <Button
        backgroundColor={'#fc544b'}
        size={'sm'}
        color={'white'}
        height={'35px'}
        // isLoading={isLoading}
        boxShadow={'0 2px 4px 0 #fc544b, 0 3px 4px 0 rgba(0, 0, 0, 0.19)'}
        _hover={{
          bg: 'white.500',
          //transform: 'translateY(-2px)',
        }}
        _focus={{
          bg: 'red.500',
        }}
        onClick={onClickCancel && onClickCancel}
      >
        <Text size={'sm'}> {title2}</Text>
      </Button>
    </Flex>
  );
};
export const customDeactivateResetBtn = ({
  title1,
  title2,
  isLoading,
  isDisabled,
  onClickReset,
  onClickSubmit,
}) => {
  return (
    <Flex alignSelf={'flex-start'} width={'100%'} marginTop={5}>
      <Button
        // boxShadow={'2xl'}
        backgroundColor={'black'}
        size={'sm'}
        height={'35px'}
        color={'white'}
        marginRight={2}
        isDisabled={isDisabled}
        isLoading={isLoading}
        display="none"
        boxShadow={
          '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
        }
        _hover={{
          bg: 'white.500',
          // background: 'gray',
          transform: 'translateY(-2px)',
          // boxShadow: 'lg',
        }}
        // _focus={{
        //   bg: 'gray',
        // }}
        onClick={onClickSubmit}
      >
        <Text size={'sm'}>{title1}</Text>
      </Button>
      <Button
        backgroundColor={'#ffa426'}
        size={'sm'}
        color={'white'}
        height={'35px'}
        // isLoading={isLoading}
        boxShadow={'0 2px 4px 0 #ffa426, 0 3px 4px 0 rgba(0, 0, 0, 0.19)'}
        _hover={{
          bg: 'white.500',
          transform: 'translateY(-2px)',
        }}
        // _focus={{
        //   bg: 'gray',
        // }}
        onClick={onClickReset}
      >
        <Text size={'xs'}> {title2}</Text>
      </Button>
    </Flex>
  );
};
export const customResetBtn = ({ title, isLoading, onClickReset }) => {
  return (
    <Flex alignSelf={'flex-start'} width={'100%'} marginTop={5}>
      <Button
        backgroundColor={'#ffa426'}
        size={'sm'}
        width={'30%'}
        color={'white'}
        height={'35px'}
        // isLoading={isLoading}
        boxShadow={'0 2px 4px 0 #ffa426, 0 3px 4px 0 rgba(0, 0, 0, 0.19)'}
        _hover={{
          bg: 'white.500',
          transform: 'translateY(-2px)',
        }}
        onClick={onClickReset}
      >
        <Text size={'xs'}> {title}</Text>
      </Button>
    </Flex>
  );
};

export const customBtn = ({ title, isLoading, onClick, backgroundColor }) => {
  return (
    <Flex alignSelf={'flex-start'} width={'100%'} marginTop={5}>
      <Button
        backgroundColor={backgroundColor}
        size={'sm'}
        color={'white'}
        width={'30%'}
        height={'35px'}
        // isLoading={isLoading}
        boxShadow={'0 2px 4px 0 gray, 0 3px 4px 0 rgba(0, 0, 0, 0.19)'}
        _hover={{
          bg: 'white.500',
          transform: 'translateY(-2px)',
        }}
        onClick={onClick}
      >
        <Text size={'xs'}> {title}</Text>
      </Button>
    </Flex>
  );
};

export const customAddNewGameBtn = ({ title1, title2 }) => {
  return (
    <Flex alignSelf={'flex-start'} width={'100%'} marginTop={5}>
      <Button
        // boxShadow={'2xl'}
        backgroundColor={'black'}
        size={'sm'}
        height={'32px'}
        // width={'8%'}
        color={'white'}
        marginRight={2}
        boxShadow={
          '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
        }
        _hover={{
          bg: 'white.500',
          background: 'gray',
          transform: 'translateY(-2px)',
          // boxShadow: 'lg',
        }}
        _focus={{
          bg: 'gray',
        }}
      >
        <Text size={'sm'}>{title1}</Text>
      </Button>
      <Button
        backgroundColor={'#fc544b'}
        size={'sm'}
        color={'white'}
        height={'30px'}
        width={'8%'}
        boxShadow={'0 2px 4px 0 #fc544b, 0 3px 4px 0 rgba(0, 0, 0, 0.19)'}
        _hover={{
          bg: 'white.500',
          transform: 'translateY(-2px)',
        }}
        _focus={{
          bg: 'gray',
        }}
      >
        <Text size={'xs'}> {title2}</Text>
      </Button>
    </Flex>
  );
};

export const customParentHead = () => {
  return (
    <Flex
      padding={'2.5%'}
      alignItems={'center'}
      flex={1}
      width={'100%'}
      background={'#F5F5F5'}
      justifyContent={'space-between'}
    >
      <SimpleGrid width={'100%'} columns={5} spacingX={'50px'}>
        <Text
          fontWeight={'bold'}
          color={theme.customColors.blackAdminColor[200]}
        >
          Property Name
        </Text>
        <Text
          fontWeight={'bold'}
          color={theme.customColors.blackAdminColor[200]}
        >
          {/* Property Name */}
        </Text>

        <Text
          fontWeight={'bold'}
          color={theme.customColors.blackAdminColor[200]}
        >
          Property Value
        </Text>

        <Text
          fontWeight={'bold'}
          color={theme.customColors.blackAdminColor[200]}
        >
          Description
        </Text>

        <Text
          fontWeight={'bold'}
          marginStart={'9%'}
          color={theme.customColors.blackAdminColor[200]}
        >
          Action
        </Text>
      </SimpleGrid>
    </Flex>
  );
};

// export const handleonChange = (evt, key, setValue, value) => {
//   const ev = evt.target.value;
//   setValue({
//     ...value,
//     [key]: ev,
//   });
// };

export const checkMandatory = ({ value, mandatoryFields }) => {
  let ifError = false;
  let keys = Object.keys(value);
  console.log(value, 'gg', mandatoryFields, 'key', keys);
  if (keys.length) {
    keys.map(key => {
      if (
        mandatoryFields[key] &&
        (value[key] === '' || value[key] === undefined || value[key] === 0)
      )
        ifError = true;
      // return ifError;
    });
  } else {
    ifError = true;
  }
  return ifError;
};

export const Toasting = ({ title, status, description }) => {
  let toast = useToast();
  toast({
    title: title,
    status: status,
    duration: 3000,
    isClosable: true,
    description: description,
  });
};
export const CustomEditDeletebtn = ({
  onClickEdit,
  onClickDelete,
  deleteFunction,
}) => {
  const { currentLangData } = React.useContext(LangContext);

  return (
    <>
      <Button {...styles.editbtn}>{currentLangData?.app?.edit}</Button>
      <Button {...styles.Delbtn} onClick={onClickDelete}>
        {currentLangData?.app?.delete}
      </Button>
    </>
  );
};
