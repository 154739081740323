import React from 'react';
import { Box, Button, Flex, Text, Input } from '@chakra-ui/react';
import style from '../../pages/Reports/styles';
import styles from './styles';
import { MiniHeader } from '../../components/common/customComponent';
import { useHistory } from 'react-router-dom';
import { TableComp } from '../../components/common/table';
import {
  listMiddlewareAuthentications,
  colMiddlewareAuthentications,
} from '../../context/data';
import { SingleComponentCreateButtonTableHeader } from '../../components/common/SingleComponentCreateButtonTableHeader';

export const MiddlewareAuthentications = () => {
  let history = useHistory();
  return (
    <Flex flexDirection={"column"} bgColor={"#FAFDFB"}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'Middleware Authentications',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path1: 'Reports',
          useSlash:'true',
          path2: 'MiddlewareAuthentications',
        })}
      </Flex>

      <Flex {...style.box} background={'#fafdfb'} padding={10}>
        <Flex {...style.main}>
          <Flex {...style.buttoncontainer}>
            <Button
              {...style.createbutton}
            >
              Create
            </Button>
          </Flex>
          <Flex {...styles.uniqueKeyOuterContainer} marginTop={7}>
            <Text>Unique Key</Text>
            <Flex {...styles.uniqueKeyContainer}>
              <Text color={'black'} paddingX={'1%'}>8275082GHFDSFD984730</Text>
            </Flex>
          </Flex>
          <TableComp
            list={listMiddlewareAuthentications}
            screenName="LuckDrawList"
            colNames={colMiddlewareAuthentications}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
