import React from 'react';
import { Box, Button, Flex, Text, Stack, Circle } from '@chakra-ui/react';
import style from '../components/common/style';
import { TableComp } from '../components/common/table';
import { listInboxMessage, colInboxMessage } from '../context/data';
import { useHistory } from 'react-router-dom';
import { MiniHeader } from '../components/common/customComponent';

const InboxMessage = () => {
  let history = useHistory();
  console.log('InboxMessage gg bro');
  return (
    <Flex flexDirection={"column"}>
    <Flex {...style.miniheader}>

    {MiniHeader({
      title: 'Inbox Messages List',
      path: 'Dashboard',
      onclickForRoute: () => history.push('/Dashboard'),
      path1: 'Game Reward Management',
      useSlash:true,
      path2: 'Inbox Messaging',
    })}
    </Flex>
    <Flex {...style.box} background={'#fafdfb'} padding={10}>
      <Flex {...style.upperbutton}>
        <Stack direction="row" spacing={4} align="center">
          <Button {...style.solidbutton}>
            <Text>All</Text>
            <Circle marginStart={2} size="30px" bg="black" color="white">
            2961
            </Circle>
          </Button>
          <Button {...style.ghostbutton}>
            <Text>Draft</Text>
            <Circle marginStart={2} size="30px" bg="black" color="white">
              14
            </Circle>
          </Button>
          <Button {...style.ghostbutton}>
            <Text>Sent</Text>
            <Circle marginStart={2} size="90%" bg="black" color="white">
            2928
            </Circle>
          </Button>
          <Button {...style.ghostbutton}>
            <Text>Save</Text>
            
            <Circle marginStart={2} size="30px" bg="black" color="white">
              19
            </Circle>
          </Button>
        </Stack>
      </Flex>
      <Flex {...style.main}>
        <Flex {...style.buttoncontainer}>
          <Button
            {...style.createbutton}
            onClick={() => history.push('/CreateInboxMessage')}
          >
            Create
          </Button>
        </Flex>
        <TableComp
          list={listInboxMessage}
          screenName="LuckDrawList"
          colNames={colInboxMessage}
        />
      </Flex>
      </Flex>
    </Flex>
  );
};
export default InboxMessage;
