import React, { useState, useEffect } from 'react';
import {
  Flex,
  Text,
  Divider,
  Skeleton,
  useToast,
  SimpleGrid,
} from '@chakra-ui/react';
import theme from '../config/color';
import {
  customInputImage2,
  customInputLabel,
  customUpdateCancelBtn,
  checkMandatory,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import ApiManager from '../config/apiManager';
import style from '../components/common/style';
import { uploadImage } from '../config/imageUploader';
import { useHistory, useLocation } from 'react-router-dom';
import { MiniHeader } from '../components/common/customComponent';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import {
  dropdownCustom,
} from '../components/bannerComponents/customBannerComponents';

const CreateFinanceBanner = () => {
  let status = ['Active', 'Inactive'];
  let page = ['KK',  'PP'];

  const toast = useToast();
  let history = useHistory();
  let location = useLocation();

  let tempArray = [];
  let dataOfEdit = '';

  let apiManager = ApiManager.getInstance();

  let initialValue = {
    SelectLink:'',
    url: '',
    name: '',
    sequence:'',
    status: '',
    position: '',
    deeplink: '',
    campaign: '',
    description: '',
    bannerType: '',
    mobileBanner: '',
    endDate: new Date(),
    startDate: new Date(),
  };

  let mandatoryFields = {
    name: true,
    sequence :true,
    status: true,
    page_type:true,
    position: false,
    endDate: true,
    startDate: true,
    bannerType: false,
  };
  const [error, setError] = useState(null);
  const [editData, setEditData] = useState([]);
  const [s3image, setS3Image] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [value, setValue] = useState(initialValue);
  const [imageView, setImageView] = useState('');
  const [changeApi, setChangeApi] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);

  // Handle Functions //
  const handleonChange = (evt, key) => {
    console.log('EVENT=>', evt.target.value);
    const ev = evt.target.value;
    setValue({
      ...value,
      [key]: ev,
    });
  };



  useEffect(() => {

    console.log('location=>', location);

    if (location?.state?.data != null || location?.state?.data != undefined) {

      dataOfEdit = location?.state?.data;
     
      setValue({
        ...value,
        name: dataOfEdit?.name,
        status: dataOfEdit?.status,
        page: dataOfEdit?.page,
        sequence: dataOfEdit?.sequence,
        
      });
      setS3Image(dataOfEdit?.image);

      console.log('dataOfEdit', dataOfEdit);
      let changeApi = location?.state?.useEditApi;
      setChangeApi(changeApi);
    }
    setEditData(dataOfEdit);
  }, []);


  console.log('value', value);

  // API CALLING //
  const onClickSubmit = () => {
    setLoading(true);
    console.log('Value', value);
    let ifError = checkMandatory({
      value: value,
      mandatoryFields: mandatoryFields,
    });

    if (ifError) {
      setError('Please enter mandatory fields');
      setLoading(false);
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.',
      });
    } else {
      console.log('value[mobileBanner]', value['mobileBanner']);
      let mobileBanner = changeMobileBanner(value['mobileBanner']);
      console.log('mobileBanner=>', mobileBanner);
   
      let body = {
        name: value?.name,
        image: s3image,
        status: value?.status,
        page:value?.page,
        sequence:value?.sequence,
      };
      console.log('Value==>', value, 'body=>', body);

      if (!changeApi) {
        apiManager
          .post('finance/addBanner', body)
          .then(res => {
            console.log('res=>', res);
            if (res.message === 8080) {
              setLoading(false);
              setSuccess('Successfully Recorded!');
              toast({
                title: success,
                status: 'success',
                duration: 3000,
                isClosable: true,
                description: `Recorded, Please processed to List!`,
              });
              setTimeout(() => {
                history.goBack();
              });
            }
            if (res.message === 8078) {
              setLoading(false);
              setSuccess('Failed Recorded!');
              toast({
                title: success,
                status: 'error',
                duration: 4000,
                isClosable: true,
                description: `The sequence number cannot be the same ! `,
              });
            }
            if (res.errors !== undefined || res.errors !== null) {
              if (res.message === 5001) {
                setLoading(loading);
                console.log('ERROR to 5001');
                console.log('res.messag', res.message);
                console.log('res.messag', res.errors[1]?.msg);
                toast({
                  title: error,
                  status: 'error',
                  duration: 3000,
                  isClosable: true,
                  description: `Failed,${res.errors[1]?.msg} !`,
                });
              }
            }
          })
          .catch(error => {
            setLoading(loading);
            console.log('error', error);
          });
      } else {
        let payload = {
          id: editData?._id,
          
          name: value.name,
          image: s3image,
          status: value.status,
          page:value?.page,
          sequence:value?.sequence,
        };
        console.log('Value==>', value, 'Update payload=>', payload);

        apiManager
          .post('finance/updateBanner', payload)
          .then(res => {
            console.log('updateBanner res=>', res);
            if (res.message === 8082) {
              setLoading(false);
              setSuccess('Successfully Recorded!');
              toast({
                title: success,
                status: 'success',
                duration: 3000,
                isClosable: true,
                description: `Update Successful !`,
              });
              setTimeout(() => {
                history.goBack();
              }, [3000]);
            }
            if (res.errors !== undefined || res.errors !== null) {
              if (res.message === 5001) {
                setLoading(loading);
                console.log('ERROR to 5001');
                console.log('res.messag', res.message);
                console.log('res.messag', res.errors[1]?.msg);
                toast({
                  title: error,
                  status: 'error',
                  duration: 3000,
                  isClosable: true,
                  description: `Failed,${res.errors[1]?.msg} !`,
                });
              }
            }
          })
          .catch(error => {
            setLoading(loading);
            console.log('error', error);
          });
      }
    }
  };

  const changeMobileBanner = value => {
    console.log('value=>', value);
    if (value === 'No') {
      return 0;
    } else if (value === 'Yes') {
      return 1;
    } else if (value.toString() === 'false') {
      return 0;
    } else if (value.toString() === 'true') {
      return 1;
    }
  };

  const upload = e => {
    setImageLoading(true);
    console.log('e.target=>', e.target.files[0].name);
    uploadImage(e.target.files[0])
      .then(res => {
        console.log('s3ImageResponseUri', res.location);
        setS3Image(e.target.files[0].name);
        setImageView(res?.location);
        setImageLoading(false);
      })
      .catch(error => {
        setImageLoading(false);
        console.log('error', error);
      });
  };

  return (
    <Flex flexDirection={'column'} bgColor={'#fafdfb'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: !changeApi ? 'Create Finance Banner' : 'Update Finance Banner',
          path: 'Finance',
          onclickForRoute: () => history.push('/CreateFinanceBanner'),
          path1: 'Banner',
          useSlash: 'true',
          path2: !changeApi ? 'Create' : 'Update',
          
        })}
      </Flex>
      <Flex {...style.box}>
        <Flex
          padding={'1%'}
          background={'white'}
          flexDirection={'column'}
          justifyContent={'space-around'}
        >
          <SimpleGrid width={'100%'} columns={4} spacingX={'50px'}>
           
            {customInputLabel({
              title: 'Name',
              value: value['name'],
              mandatory: true,
              onChange: e => handleonChange(e, 'name'),
            })}

            {dropdownCustom({
                title: 'Page',
                mandatory: true,
                placeholder: value['page']
                  ?  value['page']
                  : 'Select page',
                selectValue: 'Yes',
                temparray: page,
                onChange: e => handleonChange(e, 'page'),
              })}

              {dropdownCustom({
                title: 'Status',
                mandatory: true,
                placeholder: value['status']
                  ?  value['status']
                  : 'Select Status',
                selectValue: 'Yes',
                temparray: status,
                onChange: e => handleonChange(e, 'status'),
              })}

            <Flex flexDirection={'column'}>
                <Flex flexDirection={'row'}>
                  <Text
                    color={theme.customColors.blackAdminColor['100']}
                    fontSize="12px"
                    marginBottom={'2'}
                  >
                    Image
                  </Text>
                  <Text color={'red'} marginLeft={'1'}>
                    *
                  </Text>
                </Flex>
                {!imageLoading ? (
                  <Flex>
                    {customInputImage2({
                      title: '',
                      mandatory: false,
                      type: 'file',
                      src: imageView
                        ? imageView
                        : process.env.REACT_APP_S3URL + s3image,
                      onChange: e => upload(e),
                    })}
                  </Flex>
                ) : (
                  <Skeleton height="20px" />
                )}
              </Flex>
            
          </SimpleGrid>


          <SimpleGrid
            width={'100%'}
            columns={3}
            spacingX={'50px'}
            marginTop={'5'}
          >
            <Flex>
            {customInputLabel({
              title: 'Sequence(in Number)',
              value: value['sequence'],
              type: 'number',
              mandatory: true,
              onChange: e => handleonChange(e, 'sequence'),
            })}
            </Flex>
            </SimpleGrid>
         
         
          <Flex width={'100%'} marginY={10} flexDirection={'column'}>

            {customUpdateCancelBtn({
              title1: !changeApi ? 'Submit' : 'Update',
              title2: 'Cancel',
              isLoading: loading,
              onClickCancel: () => history.goBack(),
              onClickSubmit: () => onClickSubmit(),
            })}
          </Flex>
        </Flex>
      </Flex>
      <Divider marginStart={10} orientation="horizontal" />
      {renderCopyRight()}
    </Flex>
  );
};
export default CreateFinanceBanner;
