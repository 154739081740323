import React from 'react';
import {
  Tr,
  Td,
  Flex,
  Text,
  Select,
  Tooltip,
  Button,
  Spacer,
  useToast,
  Circle,
  Image,
} from '@chakra-ui/react';
import style from '../components/common/style';
import { ChecktableTwo } from '../components/common/ChecktableTwo';
import { colBannerFinanceManagement } from '../context/data';
import { useHistory } from 'react-router-dom';
import { MiniHeader } from '../components/common/customComponent';
import ApiManager from '../config/apiManager';
import { options } from '../context/data';
import LangContext from '../context/languageContext';
import { customInputLabel } from '../components/createAppVersionComponent/createAppVersionComponent';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';

const FinanceBannerManagement = () => {
  let history = useHistory();
  const { currentLangData } = React.useContext(LangContext);
  const [dataa, setDataa] = React.useState();
  const [paginat, setpaginate] = React.useState();
  const [loader, setLoader] = React.useState(false);
  const [value, setValue] = React.useState('10');
  const [success, setSuccess] = React.useState(null);
  const [valueSearch, setValueSearch] = React.useState();
  let tempArray = [];
  let toast = useToast();
  let apimanager = ApiManager.getInstance();
  // console.log('dataaaaaa>>>>>', dataa);
  const handleOnSearch = (evt, key) => {
    const ev = evt.target.value;
    // setValueSearch(ev);
    // searchItems(ev, value, 1);
  };
  const handleOnChange = (evt, key) => {
    const ev = evt.target.value;
    // console.log("event", ev)
    setValue(ev);
  };
  React.useEffect(() => {
    getALLItems(value, 1);
  }, [value]);

  const getALLItems = (limit, page) => {
    setLoader(false);
    let apimanager = ApiManager.getInstance();
    console.log('state Screen');
    const body = {
      numRecord: limit,
      page: page,
    };
    apimanager
      .get('finance/getAllBanners', body)
      .then(res => {
        if (res.message === 8084) {
          console.log('Get getAllBanners', res);
          let data = res?.result?.docs;
          res?.record?.docs?.map((value, index) => {
            tempArray.push(value);
          });
          setDataa(tempArray);
          setpaginate(res?.record);
          setLoader(true);
        }
      })
      .catch(error => {
        console.log('error', error);
        setLoader(true);
      });
  };
  const deleteItem = item => {
    const body = {
      id: item._id,
    };
    console.log('body>>>>', body);
    apimanager
      .post('finance/deleteBanner', body)
      .then(response => {
        if (response.message === 8083) {
          setSuccess('Successfully Deleted!');
          toast({
            title: success,
            status: 'success',
            duration: 3000,
            isClosable: true,
            description: `Deleted, Please processed to List!`,
          });
          getALLItems();
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const navigateToEditPage = editData => {
    console.log('editData=>', editData);
    history.push({
      pathname: '/CreateFinanceBanner',
      state: { data: editData, useEditApi: true },
    });
  };
  return (
    <Flex flexDirection={'column'}>
      <Flex {...style.miniheader} boxShadow={'lg'} borderBottomWidth={'1px'}>
        {MiniHeader({
          title: 'Banner',
          path: 'Finance',
          onclickForRoute: () => history.push('/FinanceBannerManagement'),
          path2: 'Banner',
        })}
      </Flex>

      <Flex {...style.box} background={'#fafdfb'}>
        <Flex {...style.main}>
          <Flex {...style.buttoncontainer}>
            <Button
              {...style.createbutton}
              onClick={() => history.push('/CreateFinanceBanner')}
            >
              Create Banner
            </Button>
          </Flex>
          <Flex {...style.optionFlex}>
            <Flex {...style.showentries} alignItems={'center'} fontSize={'sm'}>
              <Text>{currentLangData?.app?.show}</Text>
              <Select
                {...style.select}
                onChange={e => handleOnChange(e, 'numrecords')}
              >
                {options.map(option => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </Select>
              <Text>{currentLangData?.app?.entries}</Text>
            </Flex>
            <Spacer />
            <Flex alignItems={'center'} {...style.showentries}>
            </Flex>
          </Flex>
          <ChecktableTwo
            datalist={dataa}
            TotDocs={paginat}
            loaderr={loader}
            headerNames={colBannerFinanceManagement}
            onPageChange={page => getALLItems(value, page)}
            renderItem={({ item, index }) => {
              console.log('renderitem', item);
              let startdate = new Date(item?.startDate * 1000);
              let enddate = new Date(item?.endDate * 1000);
              return (
                <Tr key={index} width={'100%'}>
                  {/* <Td>{startdate.toLocaleDateString()}</Td>
                  <Td>{enddate.toLocaleDateString()}</Td> */}
                  <Td>{item?.name}</Td>

                  <Td>
                    <Circle
                      width={'20'}
                      paddingY={'4px'}
                      paddingX={'10px'}
                      bg="#34395e"
                      color="white"
                    >
                      {item?.page}
                    </Circle>
                  </Td>

                  <Td>
                    <Circle
                      width={'20'}
                      paddingY={'4px'}
                      paddingX={'10px'}
                      bg="#34395e"
                      color="white"
                    >
                      {item?.sequence}
                    </Circle>
                  </Td>

                  <Td>
                    <Circle
                      width={'20'}
                      paddingY={'4px'}
                      paddingX={'10px'}
                      bg="#34395e"
                      color="white"
                    >
                      {item?.status}
                    </Circle>
                  </Td>
                  <Td>
                    <Image
                      width={100}
                      src={process.env.REACT_APP_S3URL + item?.image}
                    ></Image>
                  </Td>
                  <Td
                    position={'relative'}
                    display={'inline-flex'}
                    verticalAlign={'middle'}
                  >
                    <Tooltip label={currentLangData?.app?.edit}>
                      <Button
                        {...style.editbtn}
                        leftIcon={<EditIcon />}
                        onClick={() => navigateToEditPage(item)}
                      ></Button>
                    </Tooltip>
                    <Tooltip label={currentLangData?.app?.delete} bg={'red'}>
                      <Button
                        {...style.Delbtn}
                        leftIcon={<DeleteIcon />}
                        onClick={() => deleteItem(item)}
                      ></Button>
                    </Tooltip>
                  </Td>
                </Tr>
              );
            }}
          />
        </Flex>
      </Flex>


        

    </Flex>
  );
};
export default FinanceBannerManagement;
