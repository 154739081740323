export const lineOptions = {
  maintainAspectRatio: false,
  animation: false,
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        barPercentage: 0.7,
        categoryPercentage: 0.4,
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        barPercentage: 0.7,
        categoryPercentage: 0.4,
      },
    ],
  },
};

export const lineData = {
  labels: ['100', '200', '300', '400', '500', '600', '700', '800'],
  datasets: [
    {
      lagend: 'none',
      data: [2.5, 3, 3, 0.9, 1.3, 1.8, 3.8, 1.5],
      borderColor: '#ff8084',
      backgroundColor: '#ff8084',
      borderWidth: 2,
    },
    {
      lagend: 'none',
      data: [3.8, 1.8, 4.3, 2.3, 3.6, 2.8, 2.8, 2.8],
      borderColor: '#a5a5a5',
      backgroundColor: '#a5a5a5',
      borderWidth: 2,
    },
  ],
};
