import { Flex, Heading, Text } from '@chakra-ui/react';
import styles from "./styles"

export const customPageHeading = () => {
  return (
    <Flex {...styles.firstFlexInParent}  padding={5}>
      <Text fontWeight={'bold'} fontSize={'26px'} color={'#34395e'}>
        Edit About Us
      </Text>
      <Text color={'gray.500'} fontSize={'sm'}>
        Dashboard / About Us Management / Edit
      </Text>
    </Flex>
  );
};
