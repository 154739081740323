import React from 'react';
import { Box, Button, Flex, Text, Spacer } from '@chakra-ui/react';
import style from '../components/common/style';
import { TableComp } from '../components/common/table';
import {
  listCNYCampaignManagement,
  colCNYCampaignManagement,
} from '../context/data';
import { useHistory } from 'react-router-dom';
import { MiniHeader } from '../components/common/customComponent';
import ApiManager from '../config/apiManager'
const CNYCampaignManagement = () => {
  let history = useHistory();
let apimanager = ApiManager.getInstance();
  return (
    <Flex flexDirection={'column'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'Compaign List',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path2: 'CNY Compiagn Management',
        })}
      </Flex>

      <Flex {...style.box} background={'#fafdfb'} padding={10}>
        <Flex {...style.main}>
          <Flex {...style.buttoncontainer}>
            <Button
              {...style.createbutton}
              onClick={() => history.push('/CreateBlackUser')}
            >
              Create
            </Button>
          </Flex>
          <TableComp
            list={listCNYCampaignManagement}
            colNames={colCNYCampaignManagement}
            screenName="LuckDrawList"
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
export default CNYCampaignManagement;
