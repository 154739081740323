import React from 'react';
import { Box, Button, Flex } from '@chakra-ui/react';
// import style from '../../components/common/style';
import style from '../../pages/Reports/styles';
import { TableComp } from '../../components/common/table';
import { listPermissions, colPermissions } from '../../context/data';
import { MiniHeader } from '../../components/common/customComponent';
import { SingleComponentCreateButtonTableHeader } from '../../components/common/SingleComponentCreateButtonTableHeader';

export const Permissions = () => {
  console.log('GameManagement gg bro');
  return (
    <SingleComponentCreateButtonTableHeader
      MiniHeaderTitle={'Permissions'}
      MiniHeaderPath={'Dashboard'}
      MiniHeaderPath1={'Reports'}
      MiniHeaderPath2={'Permissions'}
      buttonName={'Create'}
      TableList={listPermissions}
      TableColNames={colPermissions}
      TableScreenName={'LuckDrawList'}
    />
  );
};
