import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import style from '../components/common/style';
import { TableComp } from '../components/common/table';
import DateWithTitle from '../components/DateComponent/DateWithTitle';
import { useHistory } from 'react-router-dom';
import {
  listLuckyDrawRewardReport,
  colLuckyDrawRewardReport,
} from '../context/data';
import { MiniHeader } from '../components/common/customComponent';

const LuckyDrawRewardReport = () => {
  let history = useHistory();
  console.log('LuckyDrawRewardReport gg bro');
  return (
    <Flex flexDirection={'column'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'Luck Draw Rewards Report',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path2: 'Luck Draw Reward Report',
        })}
      </Flex>

      <Flex {...style.box}>
        <Flex {...style.main}>
          <Flex {...style.datesContainer}>
            <DateWithTitle
              title={'Start Date'}
              style={style.dateComponentStyles}
            />
            <DateWithTitle
              title={'End Date'}
              style={style.dateComponentStyles}
            />
          </Flex>
          <TableComp
            list={listLuckyDrawRewardReport}
            colNames={colLuckyDrawRewardReport}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
export default LuckyDrawRewardReport;
