import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import {
  Flex,
  Box,
  Button,
  Text,
  Input,
  Spacer,
  Select,
} from '@chakra-ui/react';
import 'react-datepicker/dist/react-datepicker.css';
import '.././../styles/styles.css';
import DateWithTitle from '../../components/DateComponent/DateWithTitle';
import style from './styles';
import DropDownWithTitle from '../../components/DateComponent/DropDownWithTitle';
import { MiniHeader } from '../../components/common/customComponent';
import { useHistory } from 'react-router-dom';
import { TableComp } from '../../components/common/table';
import {
  listDynamicCampaignReport,
  colDynamicCampaignReport,
} from '../../context/data';
const DynamicCampaignReport = () => {
  const [startDate, setStartDate] = useState(new Date());
  let history = useHistory();
  return (
    <Flex w={'100%'} flexDirection={'column'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'Dynamic Campaign Report',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path1: 'Reports',
          useSlash:'true',
          path2: 'DynamicCampaignReport',
        })}
      </Flex>

      <Flex {...style.cardALContainer} background={'#fafdfb'} padding={'2%'}>
        <Flex {...style.innerCard}>
          <Flex {...style.datesContainer}>
            <TableComp
              list={listDynamicCampaignReport}
              screenName="DynamicCampaignReport"
              colNames={colDynamicCampaignReport}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default DynamicCampaignReport;
