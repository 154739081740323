import React from 'react';
import { Box, Button, Flex } from '@chakra-ui/react';
import style from '../../components/common/style';
import { ChecktableOne } from '../../components/common/ChecktableOne';
import { listStatusForBanners, colStatusForBanners } from '../../context/data';
import { MiniHeader } from '../../components/common/customComponent';
import { useHistory } from 'react-router-dom';
import ApiManager from '../../config/apiManager';

export const StatusForBanners = () => {
  let history = useHistory();
  const [obj, setobj] = React.useState([]);
  const [dataa, setDataa] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  let tempArray = [];
  let ObjArray = [];

  // console.log("dataaaaaa>>>>>",dataa)

  React.useEffect(() => {
    getALLItems();
  }, []);

  const getALLItems = () => {
    let apimanager = ApiManager.getInstance();
    setLoader(false);
    console.log('state Screen');
    const body = {
      modelName: 'banner_status',
    };
    apimanager
      .post('getCategory', body)
      .then(res => {
        if (res.message === 8188) {
          res?.result?.docs?.map((value, index) => {
            // console.log('check>>>', value);
            // tempArray.push(value);

            Object.values(value?.list[0]).map((listVal, listIndex) => {
              ObjArray.push(listVal);
              console.log('listVal=>', listVal);
              let { name, id } = listVal;
              console.log('name', name);
              tempArray.push(name);
            });
            // setDataa(tempArray);
          });
          setDataa(tempArray);
          // setpaginate(res?.result);
          setLoader(true);
        }
      })
      .catch(error => {
        console.log('error', error);
        setLoader(true);
      });
  };

  // const deleteItem = item => {
  //   setLoader(false);
  //   console.log('Deletion done', item._id);
  //   let apimanager = ApiManager.getInstance();
  //   const body = {
  //     id: item._id,
  //   };
  //   console.log('body>>>>', body);
  //   apimanager
  //     .post('deletePromoCode', body)
  //     .then(response => {
  //       if (response.message === 8103) {
  //         toast({
  //           title: 'Deleted Successfully',
  //           status: 'success',
  //           duration: 3000,
  //           isClosable: true,
  //           description: '',
  //         });
  //         getALLItems(value, 1);
  //         setLoader(true);
  //       }
  //       // console.log(response, 'response');
  //     })
  //     .catch(error => {
  //       console.log(error);
  //       setLoader(true);
  //     });
  // };
  return (
    <Flex flexDirection={'column'}>
      <Flex {...style.miniheader} boxShadow={'lg'} borderBottomWidth={'1px'}>
        {MiniHeader({
          title: 'Status for Banners',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path1: 'Reports',
          useSlash: 'true',
          path2: 'Status For Banners',
        })}
      </Flex>

      <Flex {...style.box} background={'#fafdfb'}>
        <Flex {...style.main}>
          <Flex {...style.buttoncontainer}>
            <Button
              {...style.createbutton}
              onClick={() => history.push('/CreateBannerStatus')}
            >
              Create
            </Button>
          </Flex>
          <ChecktableOne
            datalist={dataa}
            loaderr={loader}
            screenName="StatusForBanners"
            screenType="buttons"
            headerNames={colStatusForBanners}
            // onDelete={item => {
            //   deleteItem(item);
            // }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
