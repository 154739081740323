import React, { useState } from 'react';
import { Box, Button, Flex } from '@chakra-ui/react';
import style from '../../pages/Reports/styles';
import { TableComp } from '../../components/common/table';
import { listIpayCredentials, colIpayCredentials } from '../../context/data';
import { MiniHeader } from '../../components/common/customComponent';
import { useHistory } from 'react-router-dom';

export const SingleComponentCreateButtonTableHeader = ({
  MiniHeaderTitle,
  MiniHeaderPath,
  MiniHeaderPath1,
  MiniHeaderPath2,
  buttonName,
  TableList,
  TableColNames,
  TableScreenName,
}) => {
  console.log('GameManagement gg bro');
  let history = useHistory();
  console.log('buttonName>>>',buttonName)
  const [buttonname,setButtonname] = useState();
  // setButtonname(buttonName);
  // console.log('setButtonname>>>',setButtonname)
  return (
    <Flex
    flexDirection={'column'}
    backgroundColor={'#FAFDFB'}
    height={'80vh'}
  >
      <Flex {...style.miniheader} boxShadow={'lg'} borderBottomWidth={'1px'}>
        {MiniHeader({
          title: MiniHeaderTitle,
          path: MiniHeaderPath,
          onclickForRoute: () => history.push('/Dashboard'),
          path1:MiniHeaderPath1,
          useSlash:'true',
          path2:MiniHeaderPath2,
        })}
      </Flex>

      <Flex {...style.box} background={'#fafdfb'}>
        <Flex {...style.main}>
          <Flex {...style.buttoncontainer}>
            <Button
              {...style.createbutton}
              onClick={() => history.push('/CreateMaintenance')}
            >
              Create
            </Button>
          </Flex>
          <TableComp
            list={TableList}
            screenName={TableScreenName}
            colNames={TableColNames}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
