import React from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import style from '../../components/common/style';
import { TableComp } from '../../components/common/table';
import { listRewardItems, colRewardItems } from '../../context/data';
import { useHistory } from 'react-router-dom';
import { MiniHeader } from '../../components/common/customComponent';

const RewardItems = () => {
  let history = useHistory();
  console.log('RewardItems gg bro');
  return (
    <Flex flexDirection={'column'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'Reward Items',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path1: 'Game Reward Management',
          useSlash:true,
          path2:'Reward Items'
        })}
      </Flex>
      <Flex {...style.box} background={'#fafdfb'} padding={10}>
        <Flex {...style.main}>
          <Flex {...style.buttoncontainer}>
            <Button
              {...style.createbutton}
              onClick={() => history.push('/CreateRewardItem')}
            >
              Create
            </Button>
          </Flex>
          <TableComp
            list={listRewardItems}
            screenName="LuckDrawList"
            colNames={colRewardItems}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
export default RewardItems;
