import React, { useState, useEffect } from 'react';
import '../styles/styles.css';
import style from './Reports/styles';
import { useHistory } from 'react-router-dom';
import ApiManager from '../config/apiManager';
import 'react-datepicker/dist/react-datepicker.css';
import LangContext from '../context/languageContext';
import {
  Flex,
  Tr,
  Td,
  useToast,
  SimpleGrid,
  Button,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { MiniHeader } from '../components/common/customComponent';
import customDateWithTitle from '../components/DateComponent/customDropdown';
import * as XLSX from 'xlsx';

const FinanceVccDownload = () => {
  let tempArray = [];
  let tempArray1 = [];
  const toast = useToast();
  let apimanager = ApiManager.getInstance();

  const [dataa, setDataa] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const [disablebtn, setDisablebtn] = React.useState(true);
  React.useContext(LangContext);

  const [startDate, setStartDate] = React.useState(new Date());
  const { currentLangData } = React.useContext(LangContext);

  let history = useHistory();

  const handleDate = async e => {
    setDisablebtn(false);
    setStartDate(e);
    setDataa([]);
  };

  useEffect(() => {
    // let finalStartDate = startDate.getTime();
    // getALLItems(null, 1, finalStartDate);
  }, []);

  const handleItem = ()=>{
      getALLItems(startDate.getTime());
  }

  const getALLItems = (startD) => {
    setLoader(false);
    const body = {
      startDate: startD
    };
    console.log('body=>', body);
    apimanager
      .post('finance/financeReport', body)
      .then(res => {
        console.log('pageTrackingReport=>', res);

        if (res.message === 8812) {
          res?.data?.map((value, index) => {

            tempArray?.push(value);

            const exp = {
              NUMBER:index+1,
              ID:value.idNumber,
              DATE:formatDate(value.createdBy).toString(),
              TIME:formatAMPM(value.createdBy).toString(),
              NAME:value.name,
              NRIC:value.nric,
              MOBILEPHONE:'60'+value.mobilePhone,
              EMAIL:value.email,
              INCOME:value.income,
              SUBMISSION:value.submission
            }
            tempArray1?.push(exp);
          });
          setDataa(tempArray);
          setLoader(true);
          if(tempArray.length>0){
            toast({
              title: 'Exporting '+tempArray.length+' Item ('+ startDate +')',
              status: 'success',
              duration: 3000,
              isClosable: true,
              description: 'Downloading.',
            });
            exportToExcel("xox-finance-report", tempArray1);
          }else{
            toast({
              title: 'Data not available ('+ startDate +')',
              status: 'error',
              duration: 3000,
              isClosable: true,
              description: 'No record available.',
            });
          }
          
          
        }
        if (res.message === 8336) {
          setLoader(true);
          toast({
            title: 'Mongodb pagination Failed',
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: 'Failed to Fetch.',
          });
        }
        if (res.message === 2454) {
          setLoader(true);
          toast({
            title: 'No Data Found',
            status: 'error',
            duration: 3000,
            isClosable: true,
            description: 'Add Data to Display.',
          });
        }
      })
      .catch(error => {
        console.log('error', error);
        setLoader(true);
      });
  };

  const formatDate = (date) => {
   var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [day, month, year ].join('');
  };

const formatAMPM = (date) => {

    var d = new Date(date);
    var hours = d.getHours();
    var minutes = d.getMinutes();

    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; 
    hours = hours < 10 ? '0'+hours : hours;
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + '' + minutes + '' +ampm;
    return strTime;
}

const exportToExcel = (fileName, data) => {
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(data);

  const headerRow = ['*',	'ID',	'DATE',	'TIME',	'NAME',	'NRIC',	'MOBILE PHONE',	'EMAIL',	'INCOME',	'SUBMISSION'];
  XLSX.utils.sheet_add_aoa(ws, [headerRow], { origin: 'A1' });

  const headerStyle = {
    font: {
      bold: true,
      color: { rgb: 'FFFFFF' }, 
    },
    fill: {
      fgColor: { rgb: 'FFFF00' }, 
    },
    border: {
      bottom: { style: 'thin', color: '000000' },
      right: { style: 'thin', color: '000000' },
      left: { style: 'thin', color: '000000' },
      top: { style: 'thin', color: '000000' },
    },
  };
  
  headerRow.forEach((header, colIndex) => {
    const cellAddress = XLSX.utils.encode_cell({ c: colIndex, r: 0 });
    ws[cellAddress].s = headerStyle;
  });

  const columnWidths = [5, 10, 10, 10, 15, 10, 15, 20, 10, 15];
  ws['!cols'] = columnWidths.map((width, colIndex) => ({ wch: width }));

  XLSX.utils.book_append_sheet(wb, ws, 'DATA');
  const outputFile = `${fileName}.xlsx`;
  XLSX.writeFile(wb, outputFile);
};




  return (
    <Flex w={'100%'} flexDirection={'column'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'Vcc Download',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path1: 'Finance',
          useSlash: true,
          path2: 'VccDownload',
        })}
      </Flex>

      <Flex {...style.cardALContainer} background={'#fafdfb'} padding={'2%'}>
        <Flex {...style.innerCard}>
          <Flex {...style.datesContainer} padding={5} width={'80%'}>
            <SimpleGrid
              width={'100%'}
              alignContent={'space-between'}
              columns={3}
              spacingX={'50px'}
            >
              {/* {DatewithTimePicker({
                title: 'Select Date Range',
                mandatory: false,
                startDateRange: startDate,
                endDateRange: endDate,

                changePicker: true,
                onChange: e => handleDate(e),
              })} */}
              {customDateWithTitle({
                title: 'Select Date',
                mandatory: true,
                selectedDate: startDate,
                onChange: e => handleDate(e),
              })}

              {disablebtn?<Button {...style.downloadbutton} marginTop={7} isDisabled>
                {currentLangData?.app?.download}
              </Button>:<Button {...style.downloadbutton} marginTop={7} isActive onClick={() => handleItem()}>
                {currentLangData?.app?.download}
              </Button>}
              
            </SimpleGrid>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default FinanceVccDownload;
