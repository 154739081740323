import React from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import style from '../components/common/style';
import { TableComp } from '../components/common/table';
import { listMobilebanner, colMobilebanner } from '../context/data';
import { useHistory } from 'react-router-dom';
import { MiniHeader } from '../components/common/customComponent';

const Mobilebanner = () => {
  let history = useHistory();
  console.log('Mobilebanner gg bro');
  return (
    <Flex flexDirection={"column"}>
    <Flex {...style.miniheader}>

    {MiniHeader({
      title: 'Mobile Banners',
      path: 'Dashboard ',
      onclickForRoute: () => history.push('/Dashboard'),
      path2:'Mobile Banners',
    })}
    </Flex>
    <Flex {...style.box} background={'#fafdfb'} padding={10}>
      <Flex {...style.main}>
        <Flex {...style.buttoncontainer}>
          <Button
            {...style.createbutton}
            onClick={() => history.push('/CreateMobileBanner')}
          >
            Create
          </Button>
        </Flex>
        <TableComp
          list={listMobilebanner}
          screenName="LuckDrawList"
          colNames={colMobilebanner}
        />
      </Flex>
    </Flex>
    </Flex>
  );
};
export default Mobilebanner;
