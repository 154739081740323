import React from 'react';
import { Box, Button, Flex ,Text} from '@chakra-ui/react';
import style from '../../components/common/style';
import { TableComp } from '../../components/common/table';
import { listSoftpinimport, colSoftpinimport} from '../../context/data';
import { MiniHeader } from '../../components/common/customComponent';
import { useHistory } from 'react-router-dom';

const Softpinimport = () => {
  let history = useHistory()
  return (
    <Flex flexDirection={"column"}>
    <Flex {...style.miniheader}>

    {MiniHeader({
      title: 'Softpin Import',
      path: 'Dashboard',
      onclickForRoute: () => history.push('/Dashboard'),
      path2:'Softpins',
    })}
    </Flex>
    <Flex {...style.box} background={'#fafdfb'} padding={10}>
    <Flex {...style.main}>
    <Flex {...style.buttoncontainer}>
        <Button {...style.createbutton} onClick={()=>history.push("/CreateSoftpinImport")}>Upload File</Button>
      </Flex>
    <TableComp
      list={listSoftpinimport}
      screenName="LuckDrawList"
      colNames={colSoftpinimport}
    />
    </Flex>
    </Flex>
  </Flex>
  )
}

export default Softpinimport