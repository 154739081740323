import React, { useState } from 'react';
import { Flex, Box, Text, Select } from '@chakra-ui/react';
import 'react-datepicker/dist/react-datepicker.css';
import '.././../styles/styles.css';
import styles from './styles';

const DateWithTitle = ({ title, style }) => {
  console.log('Style            ', style);
  return (
    <Flex {...style}>
      <Flex flexDirection={'row'}>
        <Text fontSize={'12'}>{title}</Text>
        <Text color={'#ff0000'}>*</Text>
      </Flex>
      <Select placeholder="Select option" {...styles.selectorStyle}>
        <option value="option1">Pay Bill</option>
        <option value="option2">Add On</option>
        <option value="option3">All Transactions</option>
      </Select>
    </Flex>
  );
};
export default DateWithTitle;
