import React, { useState, useEffect } from 'react';
import {
  Flex,
  Text,
  Divider,
  Skeleton,
  useToast,
  SimpleGrid,
} from '@chakra-ui/react';
import theme from '../config/color';
import {
  customInputImage,
  customInputLabel,
  customUpdateCancelBtn,
  checkMandatory,
} from '../components/createAppVersionComponent/createAppVersionComponent';
import ApiManager from '../config/apiManager';
import style from '../components/common/style';
import { uploadImage } from '../config/imageUploader';
import { useHistory, useLocation } from 'react-router-dom';
import { MiniHeader } from '../components/common/customComponent';
import { renderCopyRight } from '../components/aboutUsComponent/customCompoent';
import {
  dropdownCustom,
} from '../components/bannerComponents/customBannerComponents';

const CreateFinanceTc = () => {
  let page = ['KK',  'PP'];

  const toast = useToast();
  let history = useHistory();
  let location = useLocation();

  let dataOfEdit = '';

  let apiManager = ApiManager.getInstance();

  let initialValue = {
    SelectLink:'',
    url: '',
    name: '',
    page: '',
  };

  let mandatoryFields = {
    name: true,
    page: true,
    url: false,
  };
  const [error, setError] = useState(null);
  const [editData, setEditData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [value, setValue] = useState(initialValue);
  const [changeApi, setChangeApi] = useState(false);

  // Handle Functions //
  const handleonChange = (evt, key) => {
    console.log('EVENT=>', evt.target.value);
    const ev = evt.target.value;
    setValue({
      ...value,
      [key]: ev,
    });
  };


  useEffect(() => {

    console.log('location=>', location);

    if (location?.state?.data != null || location?.state?.data != undefined) {

      dataOfEdit = location?.state?.data;
     
      setValue({
        ...value,
        name: dataOfEdit?.name,
        page: dataOfEdit?.page,
        url: dataOfEdit?.url,
        
      });

      console.log('dataOfEdit', dataOfEdit);
      let changeApi = location?.state?.useEditApi;
      setChangeApi(changeApi);
    }
    setEditData(dataOfEdit);
  }, []);

  console.log('value', value);

  // API CALLING //
  const onClickSubmit = () => {
    setLoading(true);
    console.log('Value', value);
    let ifError = checkMandatory({
      value: value,
      mandatoryFields: mandatoryFields,
    });

    if (ifError) {
      setError('Please enter mandatory fields');
      setLoading(false);
      toast({
        title: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Failed to Submit.',
      });
    } else {
   
      let body = {
        name: value?.name,
        page: value?.page,
        url: value?.url
      };
      console.log('Value==>', value, 'body=>', body);

      if (!changeApi) {
        apiManager
          .post('finance/addTc', body)
          .then(res => {
            console.log('res=>', res);
            if (res.message === 8011) {
              setLoading(false);
              setSuccess('Successfully Recorded!');
              toast({
                title: success,
                status: 'success',
                duration: 3000,
                isClosable: true,
                description: `Recorded, Please processed to List!`,
              });
              setTimeout(() => {
                history.goBack();
              });
            }
            if (res.message === 8012) {
              setLoading(false);
              setSuccess('Failed Recorded!');
              toast({
                title: success,
                status: 'error',
                duration: 4000,
                isClosable: true,
                description: `Pages cannot be duplicated !`,
              });
            }
            if (res.message === 8078) {
              setLoading(false);
              setSuccess('Failed Recorded!');
              toast({
                title: success,
                status: 'error',
                duration: 4000,
                isClosable: true,
                description: `Enter Mandatory Link OR DeepLink `,
              });
            }
            if (res.errors !== undefined || res.errors !== null) {
              if (res.message === 5001) {
                setLoading(loading);
                console.log('ERROR to 5001');
                console.log('res.messag', res.message);
                console.log('res.messag', res.errors[1]?.msg);
                toast({
                  title: error,
                  status: 'error',
                  duration: 3000,
                  isClosable: true,
                  description: `Failed,${res.errors[1]?.msg} !`,
                });
              }
            }
          })
          .catch(error => {
            setLoading(loading);
            console.log('error', error);
          });
      } else {
        let payload = {
          id: editData?._id,
          name: value?.name,
          page: value?.page,
          url: value?.url
        };
        console.log('Value==>', value, 'Update payload=>', payload);

        apiManager
          .post('finance/updateTc', payload)
          .then(res => {
            console.log('updateBanner res=>', res);
            if (res.message === 8082) {
              setLoading(false);
              setSuccess('Successfully Recorded!');
              toast({
                title: success,
                status: 'success',
                duration: 3000,
                isClosable: true,
                description: `Update Successful !`,
              });
              setTimeout(() => {
                history.goBack();
              }, [3000]);
            }
            if (res.errors !== undefined || res.errors !== null) {
              if (res.message === 5001) {
                setLoading(loading);
                console.log('ERROR to 5001');
                console.log('res.messag', res.message);
                console.log('res.messag', res.errors[1]?.msg);
                toast({
                  title: error,
                  status: 'error',
                  duration: 3000,
                  isClosable: true,
                  description: `Failed,${res.errors[1]?.msg} !`,
                });
              }
            }
          })
          .catch(error => {
            setLoading(loading);
            console.log('error', error);
          });
      }
    }
  };

  return (
    <Flex flexDirection={'column'} bgColor={'#fafdfb'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: !changeApi ? 'Create Finance T&C' : 'Update Finance T&C',
          path: 'Finance',
          onclickForRoute: () => history.push('/CreateFinanceTc'),
          path1: 'T&C',
          useSlash: 'true',
          path2: !changeApi ? 'Create' : 'Update',
        })}
      </Flex>
      <Flex {...style.box}>
        <Flex
          padding={'1%'}
          background={'white'}
          flexDirection={'column'}
          justifyContent={'space-around'}
        >
          <SimpleGrid width={'100%'} columns={3} spacingX={'50px'}>
           
            {customInputLabel({
              title: 'Name',
              value: value['name'],
              mandatory: true,
              onChange: e => handleonChange(e, 'name'),
            })}

            {customInputLabel({
              title: 'Url',
              value: value['url'],
              mandatory: true,
              onChange: e => handleonChange(e, 'url'),
            })}

            {dropdownCustom({
                title: 'Page',
                mandatory: true,
                placeholder: value['page']
                  ? value['page']
                  : 'Select Page',
                selectValue: 'Yes',
                temparray: page,
                onChange: e => handleonChange(e, 'page'),
              })}
            
          </SimpleGrid>
         
         
          <Flex width={'100%'} marginY={10} flexDirection={'column'}>

            {customUpdateCancelBtn({
              title1: !changeApi ? 'Submit' : 'Update',
              title2: 'Cancel',
              isLoading: loading,
              onClickCancel: () => history.goBack(),
              onClickSubmit: () => onClickSubmit(),
            })}
          </Flex>
        </Flex>
      </Flex>
      <Divider marginStart={10} orientation="horizontal" />
      {renderCopyRight()}
    </Flex>
  );
};
export default CreateFinanceTc;
