import React from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import style from '../components/common/style';
import { TableComp } from '../components/common/table';
import { listMotivationalQuotes, colMotivationalQuotes } from '../context/data';
import { useHistory } from 'react-router-dom';
import { MiniHeader } from '../components/common/customComponent';

const MotivationalQuotes = () => {
  let history = useHistory();
  console.log('MotivationalQuotes gg bro');
  return (
    <Flex flexDirection={"column"}>
    <Flex {...style.miniheader}>

    {MiniHeader({
      title: 'Motivational quote List',
      path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path2: 'Motivational Quotes',
    })}
    </Flex>
    
    <Flex {...style.box} background={'#fafdfb'} padding={10}>
      <Flex {...style.main}>
        <Flex {...style.buttoncontainer}>
          <Button
            {...style.createbutton}
            onClick={() => history.push('/CreateBlackUser')}
          >
            Create
          </Button>
        </Flex>
        <TableComp list={ listMotivationalQuotes} colNames={colMotivationalQuotes }  screenName="LuckDrawList"/>
      </Flex>
    </Flex>
  </Flex>
  );
};
export default MotivationalQuotes;
