import React, { useEffect, useState } from 'react';
import { Text } from '@chakra-ui/react';
import { menuItems } from '../../navigation/Routes';
import { HamburgerIcon, ArrowRightIcon } from '@chakra-ui/icons';
import   MenuItem  from './MenuItem';
import { CustomMenuItemsList } from '../../navigation/Routes';
import theme from '../../config/color';

// added more menuItems for testing //

const CustomSideMenu = props => {
  const [inactive, setInactive] = useState(false);

  useEffect(() => {
    if (inactive) {
      removeActiveClassFromSubMenu();
    }

    props.onCollapse(inactive);
  }, [inactive]);

  const removeActiveClassFromSubMenu = () => {
    document.querySelectorAll('.sub-menu').forEach(el => {
      el.classList.remove('active');
    });
  };

  /*just a little improvement over click function of menuItem
    Now no need to use expand state variable in MenuItem component
  */
  useEffect(() => {
    let menuItems = document.querySelectorAll('.menu-item');
    menuItems.forEach(el => {
      el.addEventListener('click', e => {
        const next = el.nextElementSibling;
        removeActiveClassFromSubMenu();
        menuItems?.forEach(el => el.classList.remove('active'));
        el.classList.toggle('active');
        console.log('next', next);
        if (next !== null) {
          next.classList.toggle('active');
        }
      });
    });
  }, []);

  return (
    <div
      className={`side-menu ${inactive ? 'inactive' : ''}`}
      style={{ display: props.stylesDisplay }}
    >
      <div className="top-section">
        <Text
          fontSize={'16px'}
          fontWeight={'bold'}
          textAlign={'center'}
          fontFamily={'heading'}
        >
          {!inactive ? 'BLACK ADMIN' : 'BA'}
        </Text>
        <div onClick={() => setInactive(!inactive)} className="toggle-menu-btn">
          <HamburgerIcon w={10} h={6} />
        </div>
      </div>

      {!inactive ? (
        <Text
          // marginY={5}
          marginTop={10}
          color={theme.customColors.blackAdminColor[300]}
          fontFamily={'sans-serif'}
        >
          APP MANAGEMENT
        </Text>
      ) : null}

      <div className="main-menu">
        <ul>
          {CustomMenuItemsList.map((menuItem, index) => (
            <MenuItem
              key={index}
              name={menuItem.name}
              exact={menuItem.exact}
              to={menuItem.to}
              path={menuItem.path}
              activity={inactive}
              component={menuItem.component}
            //   subMenus={menuItem.subMenus || []}
              iconClassName={menuItem.iconClassName}
              onClick={e => {
                if (inactive) {
                  setInactive(false);
                }
              }}
            />
          ))}
        </ul>
      </div>
      <div className="divider"></div>
    </div>
  );
};

export default CustomSideMenu;
/**
 * @author Khizer
 * @function CustomSideMenu
 **/
