import React from 'react';
import {
  Flex,
  SimpleGrid,
  Divider,
  Spacer,
  useToast,
} from '@chakra-ui/react';
import styles from '../../styles/mobileBannerStyles';
import { useHistory } from 'react-router-dom';
import style from '../../components/common/style';
import {
  customSwtich,
  customInputLabel,
  customUpdateCancelBtn,
  checkMandatory,
} from '../../components/createAppVersionComponent/createAppVersionComponent';
import customDateWithTitle from '../../components/DateComponent/customDropdown';
import { dropdownCustom,dropdownCustomSvalue } from '../../components/bannerComponents/customBannerComponents';
import { renderCopyRight } from '../../components/aboutUsComponent/customCompoent';
import {
  customTextarea,
  MiniHeader,
} from '../../components/common/customComponent';
import { useLocation } from 'react-router-dom';
import ApiManager from '../../config/apiManager';
import { isMobileList } from '../../context/data';

const BlackAppNotificationReport = () => {
  let initialValue = {
    UUID: '',
    NotificationItemName: '',
    NotificationItemId: '',
    RecievedStatus: '',
    xoxMobileUser: '',
    clickedOnNotification: '',
    landingPage: '',
    lastActivity: '',
  };
  let mandatoryFields = {
    UUID: true,
    NotificationItemName: true,
    NotificationItemId: true,
    RecievedStatus: true,
    xoxMobileUser: true,
    clickedOnNotification: true,
    landingPage: true,
    lastActivity: true,
  };
  const [value, setValue] = React.useState(initialValue);
  const [status, setstatus] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [startD, setStartD] = React.useState(new Date());
  const [endD, setendD] = React.useState(new Date());
  const [success, setSuccess] = React.useState(null);
  let apiManager = ApiManager.getInstance();
  const [countries, setCountries] = React.useState([]);
  let history = useHistory();
  let location = useLocation();
  let toast = useToast();
  let producttype = ['Mobile Internet', 'Mobile Internet Add-on'];
  let statusarray = ['Inactive', 'Active'];
  // ---------------------------
  console.log('value=>', value);
  const handleonChange = (evt, key) => {
    const ev = evt.target.value;
    console.log('event>>', ev);
    setValue({
      ...value,
      [key]: ev,
    });
  };
  const convertedDate = strDate => {
    console.log('convert', strDate);
    const dt = Date.parse(strDate);
    return dt / 1000;
  };
  return (
    <Flex
    flexDirection={'column'}
    backgroundColor={'#FAFDFB'}
    height={'80vh'}
  >
    <Flex {...style.miniheader} marginBottom={'2%'}>
      {MiniHeader({
        title: 'Black App Notification Report',
        path: 'Dashboard',
        onclickForRoute: () => history.push('/Dashboard'),
        path1: 'Reports',
        useSlash: true,
        path2: 'Black App Notification Report',
      })}
    </Flex>
    <Flex {...styles.parentFlex}>
      <SimpleGrid width={'100%'} columns={1} spacingX={'50px'}>
        {customInputLabel({
          title: 'UUID',
          mandatory: true,
          onChange: e => handleonChange(e, 'UUID'),
        })}
      </SimpleGrid>
      <SimpleGrid
        width={'100%'}
        columns={2}
        spacingX={'50px'}
        marginTop={2}
      >
        {customInputLabel({
          title: 'Notification Item Name',
          mandatory: true,
          onChange: e => handleonChange(e, 'NotificationItemName'),
        })}
         {customInputLabel({
          title: 'Notification Item Id',
          mandatory: true,
          onChange: e => handleonChange(e, 'NotificationItemId'),
        })}
      </SimpleGrid>
      <SimpleGrid
        width={'100%'}
        columns={2}
        spacingX={'50px'}
        marginTop={2}
      >
         {dropdownCustomSvalue({
          title: 'Recieved Status',
          mandatory: true,
          temparray: isMobileList,
          onChange: e => handleonChange(e, 'RecievedStatus'),
        })}
        {dropdownCustomSvalue({
          title: 'XoX Mobile User',
          mandatory: true,
          temparray: isMobileList,
          onChange: e => handleonChange(e, 'xoxMobileUser'),
        })}
      </SimpleGrid>
      <SimpleGrid
        width={'100%'}
        columns={2}
        spacingX={'50px'}
        marginTop={5}
      >
        {customDateWithTitle({
          title: 'Date/Time Recieved',
          mandatory: true,
          selectedDate: startD,
          onChange: e => setStartD(e),
        })}
         {customInputLabel({
          title: 'Clicked On Notification',
          mandatory: true,
          onChange: e => handleonChange(e, 'clickedOnNotification'),
        })}
      </SimpleGrid>
      <SimpleGrid
        width={'100%'}
        columns={2}
        spacingX={'50px'}
        marginTop={5}
      >
         {customInputLabel({
          title: 'Landing Page',
          mandatory: true,
          onChange: e => handleonChange(e, 'landingPage'),
        })}
         {customInputLabel({
          title: 'Last Activity',
          mandatory: true,
          onChange: e => handleonChange(e, 'lastActivity'),
        })}
      </SimpleGrid>

      {customUpdateCancelBtn({
        title1: 'Submit',
        title2: 'Cancel',
        onClickCancel: () => console.log('Cancel'),
        // onClickSubmit: () => onClickSubmit(),
      })}
    </Flex>
    <Spacer />
    <Divider orientation="horizontal" marginTop={5} marginBottom={2} />
    <Flex position={'sticky'} marginLeft={4}>
      {renderCopyRight()}
    </Flex>
  </Flex>
  )
}

export default BlackAppNotificationReport