import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import style from '../components/common/style';
import { TableComp } from '../components/common/table';
import { listluckreport, colNameslistluckreport } from '../context/data';
import DateWithTitle from '../components/DateComponent/DateWithTitle';
import { MiniHeader } from '../components/common/customComponent';
import { useHistory } from 'react-router-dom';

const LuckyDrawUserReport = () => {
  console.log('LuckyDrawUserReport gg bro');
  let history = useHistory();
  return (
    <Flex flexDirection={'column'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'Luck Draw User Report',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path2: 'Luck Draw User Report',
        })}
      </Flex>
      <Flex {...style.box} background={'#fafdfb'} padding={10}>
        <Flex {...style.main}>
          <Flex {...style.datesContainer}>
            <DateWithTitle
              title={'Start Date'}
              style={style.dateComponentStyles}
            />
            <DateWithTitle
              title={'End Date'}
              style={style.dateComponentStyles}
            />
          </Flex>
          <TableComp
            list={listluckreport}
            screenName="LuckyDrawUserReport"
            colNames={colNameslistluckreport}
            firstscreen={false}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
export default LuckyDrawUserReport;
