import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { Flex, Box, Text } from '@chakra-ui/react';
import { CalendarIcon } from '@chakra-ui/icons';
import 'react-datepicker/dist/react-datepicker.css';
// import '.././../styles/styles.css';
import styles from './styles';
import theme from '../../config/color';

const DatewithTimePicker = ({
  title,
  style,
  mandatory,
  onChange,
  selectedDate,
  changePicker,
  endDateRange,
  startDateRange,
  onChangeRange,
  selectedDateRange,
}) => {
  return (
    <Flex {...style} flexDirection={'column'}>
      <Flex flexDirection={'row'}>
        <Text
          color={theme.customColors.blackAdminColor['100']}
          fontSize="md"
          marginBottom={'2'}
        >
          {title}
        </Text>
        {mandatory ? null : <Text color={'#ff0000'}>*</Text>}
      </Flex>
      <Flex {...styles.iconDateContainer} borderColor={"#bcc1c6"}>
        <CalendarIcon
          w={4}
          h={4}
          margin={'2'}
          color={'gray'}
          alignSelf={'center'}
        />
        <Flex h={'100%'} borderColor={'#bcc1c6'} borderWidth={'0.1px'}></Flex>
        {!changePicker ? (
          <DatePicker
            showTimeSelect
            selected={selectedDate}
            onChange={onChange && onChange}
            dateFormat="MMMM d, yyyy h:mm aa"
            className="react-datepicker__without_border_fullWH"
          />
        ) : (
          <DatePicker
            selectsRange={true}
            // selected={selectedDateRange}
            startDate={startDateRange}
            endDate={endDateRange}
            onChange={onChange && onChange}
            // onChange={update => {
            //   setDateRange(update);
            // }}
            //withPortal
            className="react-datepicker__without_border_fullWH"
          />
        )}
      </Flex>
    </Flex>
  );
};

export default DatewithTimePicker;
