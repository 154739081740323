import React, { useState } from 'react';
import {
  Flex,
  Box,
  Button,
  Text,
  Input,
  Spacer,
  Select,
} from '@chakra-ui/react';
import 'react-datepicker/dist/react-datepicker.css';
import '.././../styles/styles.css';
import InputwithTitle from '../../components/InputwithTitle/InputwithTitle';
import DateWithTitle from '../../components/DateComponent/DateWithTitle';
import style from './styles';
import { MiniHeader } from '../../components/common/customComponent';
import DropDownWithTitle from '../../components/DateComponent/DropDownWithTitle';
import { useHistory } from 'react-router-dom';
import { TableComp } from '../../components/common/table';
import { listReferralProgram, colReferralProgram } from '../../context/data';
const ReferralProgram = () => {
  const [startDate, setStartDate] = useState(new Date());
  let history = useHistory();
  return (
    <Flex w={'100%'} flexDirection={'column'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'Referral Program Log',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          path1: 'Reports ',
          useSlash:true,
          path2: 'Referral Program',
        })}
      </Flex>

      <Flex {...style.cardALContainer} background={'#fafdfb'} padding={'2%'}>
        <Flex {...style.innerCard}>
          <Flex {...style.datesContainer} padding={'10'}>
            <DateWithTitle
              title={'Start Date'}
              style={style.dateComponentStyles}
            />
            <DateWithTitle
              title={'End Date'}
              style={style.dateComponentStyles}
            />
          </Flex>

          <Flex {...style.datesContainerWithoutPadding} paddingX={10}>
            <InputwithTitle
              title={'Email'}
              placeHolder={''}
              style={style.dateComponentStyles}
            />
            <InputwithTitle
              title={'MSISDN'}
              placeHolder={''}
              style={style.dateComponentStyles}
            />
          </Flex>
          <TableComp list={listReferralProgram} colNames={colReferralProgram} />
          <Flex marginY={6}>
            <Button {...style.downloadButton} border={0} ml={'5%'}>
              Download
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ReferralProgram;
