// import React from 'react';
// import {Flex} from '@chakra-ui/react'
// import { Tablcomp } from '../components/common/tablecomp';
// export const ComponentToPrint = React.forwardRef((props,ref) => {
//     console.log('TablComp>>>',Tablcomp)
//   return (
//     <div ref={ref}>
//         Component To print
//     </div>
//   );
// });   
