import React from 'react';
import LangContext from '../../context/languageContext';
import ReactPaginate from 'react-paginate';
import { CheckIcon, UpDownIcon } from '@chakra-ui/icons';
import { MdImportExport } from 'react-icons/md';
import { options } from '../../context/data';
import {
  Tr,
  Th,
  Td,
  Icon,
  Box,
  Flex,
  Text,
  Input,
  Table,
  Circle,
  Tbody,
  Select,
  Thead,
  Spacer,
  Button,
} from '@chakra-ui/react';
import style from '../common/style';

export const TableComp = ({
  list,
  screenName,
  colNames,
  width = 'auto',
  height = 'auto',
}) => {
  const [currentPage, setCurrentPage] = React.useState(0);
  const [selectvalue, setSelectvalue] = React.useState(10);
  const [firstScreen, setLuckyDrawUserReport] = React.useState(false);
  const [secondScreen, setSecondScreen] = React.useState(false);
  const [dynamicCampaignReport, setDynamicCampaignReport] =
    React.useState(false);
  const { currentLangData } = React.useContext(LangContext);

  const handlePageClick = ({ selected: selectedPage }) => {
    console.log('clicked', selectedPage);
    setCurrentPage(selectedPage);
  };

  let totalDatalength = list?.length;
  console.log('list', list?.length);
  console.log('list data',list)
  const PER_PAGE = selectvalue;
  console.log('No of data show in a single page>>', PER_PAGE);
  const offset = currentPage * PER_PAGE;
  console.log('offset', offset);
  const pageCount = Math.ceil(totalDatalength / PER_PAGE);

  React.useEffect(() => {
    console.log('Check props', screenName);
    if (screenName === 'LuckyDrawUserReport') {
      setLuckyDrawUserReport(true);
    } else if (screenName === 'LuckDrawList') {
      setSecondScreen(true);
    } else if (screenName === 'DynamicCampaignReport') {
      setDynamicCampaignReport(true);
    }
  }, []);
  return (
    <Flex width={"100%"} flexDirection={'column'} paddingX={5}>
      <Flex {...style.optionFlex}>
        <Flex {...style.showentries} alignItems={'center'} fontSize={'sm'}>
          <Text>Show</Text>
          <Select
            {...style.select}
            onChange={e => setSelectvalue(e.target.value)}
          >
            {options.map(option => (
              <option value={option.value}>{option.label}</option>
            ))}
          </Select>
          <Text>entries</Text>
        </Flex>
        <Spacer />
        <Flex alignItems={'center'} {...style.showentries}>
          <Text paddingY={'5px'}>Search:</Text>
          <Input {...style.searchinput}></Input>
        </Flex>
      </Flex>
      <Flex {...style.tableFlex}>
        {list.length > 0 && (
          <Table {...style.table}>
            <Thead {...style.thead}>
              <Tr>
                {colNames.map((headerItem, index) => (
                  <Th {...style.th} key={index}>
                    <Flex>
                      <MdImportExport />
                      {headerItem}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody {...style.tbody} >
              {Object.values(list)
                .slice(offset, offset + PER_PAGE)
                .map((obj, index) => (
                  <Tr {...style.tr} key={index}>
                    {Object.values(obj).map((value, index2) => (
                      <Td key={index2}>{value? value : "--"}</Td>
                    ))}
                    {secondScreen ? (
                      <Td flexDirection={'row'} width={'17%'} justifyContent={'flex-end'}>
                        <Button {...style.editbtn}>Edit</Button>
                        <Button {...style.Delbtn}>Delete</Button>
                      </Td>
                    ) : dynamicCampaignReport ? (
                      <Td>
                        <Button {...style.editbtn}>View</Button>
                        <Button {...style.Delbtn}>Download</Button>
                      </Td>
                    ) : null}
                  </Tr>
                ))}
            </Tbody>
          </Table>
        )}
      </Flex>
      <Flex {...style.tablefooter}>
        <Flex>
          <Text>
            Showing {offset} to {PER_PAGE} of {totalDatalength} entries
          </Text>
        </Flex>
        <Flex {...style.paginatecontainer}>
          <ReactPaginate
            nextLabel={'Next'}
            pageCount={pageCount}
            previousLabel={'Previous'}
            activeClassName={'active'}
            nextClassName={'page-item'}
            pageClassName={'page-item'}
            nextLinkClassName={'page-link'}
            pageLinkClassName={'page-link'}
            previousClassName={'page-item'}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            previousLinkClassName={'page-link'}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
