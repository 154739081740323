import React from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import style from '../../components/common/style';
import { TableComp } from '../../components/common/table';
import { listUpsellCompaigns, colUpsellCompaigns } from '../../context/data';
import { MiniHeader } from '../../components/common/customComponent';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const UpsellCompaigns = () => {
  let history = useHistory();
  return (
    <Flex flexDirection={'column'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'Upsell Campaigns list',
          path: 'Dashboard',
          onclickForRoute: () => history.push('/Dashboard'),
          useSlash:true,
          path2:'Upsell Campaigns',
        })}
      </Flex>
      <Flex {...style.box} background={'#fafdfb'} padding={10}>
        <Flex {...style.main}>
          <Flex {...style.buttoncontainer}>
            <Button
              {...style.createbutton}
              onClick={() => history.push('/CreateUpsellCompaign')}
            >
              Create
            </Button>
          </Flex>
          <TableComp
            list={listUpsellCompaigns}
            screenName="LuckDrawList"
            colNames={colUpsellCompaigns}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default UpsellCompaigns;
