import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { Flex, Box, Button } from '@chakra-ui/react';
import { CalendarIcon } from '@chakra-ui/icons';
import 'react-datepicker/dist/react-datepicker.css';
import '.././../styles/styles.css';
import DateWithTitle from '../../components/DateComponent/DateWithTitle';
import style from './styles';
import { MiniHeader } from '../../components/common/customComponent';

const AuditTrail = () => {
  const [startDate, setStartDate] = useState(new Date());
  return (
    <Flex  flexDirection={'column'} background={'#fafdfb'}>
      <Flex {...style.miniheader}>
        {MiniHeader({
          title: 'Audit Report',
          path: 'Dashboard',
          path1: 'Reports',
          useSlash:'true',
          path2: 'AuditTrail',
        })}
      </Flex>

      <Flex {...style.cardALContainer} background={'#fafdfb'} padding={'2%'}>
        <Flex {...style.innerCard}>
          <Flex {...style.datesContainer} padding={10}>
            <DateWithTitle
              title={'Start Date'}
              style={style.dateComponentStyles}
            />
            <DateWithTitle
              title={'End Date'}
              style={style.dateComponentStyles}
            />
          </Flex>
          <Button {...style.downloadButton} border={0} ml={'5%'}>
            Download
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default AuditTrail;
