const getUrl = mode => {
  switch (mode) {
    case 'consumerPwa':
      return 'https://consumerpwa.trax.asia:3000/?id=';
    default:
      return 'https://consumerpwa.trax.asia:3000/?id=';
  }
};

const config = {
  // black_api_url: "https://beapisvr.trax.asia/api/",
  black_api_url: 'http://13.229.208.114:3000/api/', // dev api gateway
  // black_api_url: "http://192.168.1.104:3001/api/",
  // black_api_url: "http://7937-110-38-87-186.ngrok.io/api/",
  // sso_base_url : "http://192.168.1.22:3001/api/",
  sso_base_url : "http://18.136.106.202/api/", // dev sso ip
  sso_advance_url : "http://18.136.106.202:3001/api/", // dev sso ip
  sso_advance_payment : "http://18.136.106.202:3003/api/", // dev sso ip
  black_market_api: "http://13.215.170.182:8092/api/",  //black Market Api
  // sso_base_url : "http://192.168.1.23:3001/api/",

  fcmKey: 'testFcmKey',
  facebook_appid: '300894025343706',
  // google_map_key: "AIzaSyCvlR6R50PN-7o-7UABXDTrdjIAMudbRfM",
  // resetPasswordUrl: "http://13.228.129.207:10001/resetPassword",
  // qrUrl: "https://admincms.trax.com/TracibilityInfo/?rule=",
  pwaUrl: getUrl(),
  // ----------s3 Credentials----------
  bucketName: 'xox-superapp',
  region: 'ap-southeast-1',
  // accessKeyId: 'AKIAYNUQVSIEAW7WSBJ2',
  // secretAccessKey: '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',
  accessKeyId: 'AKIAYNUQVSIEDJFP4ITM',
  secretAccessKey: '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',
  s3url: 'https://xox-superapp.s3.amazonaws.com/superCms/',
  dir: 'superCms',
  // -------Csv_Bucket--------
  bucketNamecsv: 'notification-service-files',
  regioncsv: 'ap-southeast-1',
  // accessKeyIdcsv: 'AKIAYNUQVSIECU77M26T',
  // secretAccessKeycsv: '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',
  accessKeyIdcsv: 'AKIAYNUQVSIELKPI7AQA',
  secretAccessKeycsv: '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',

  filelink: 'blackAdminMsisdn.csv',
  // bucketname: 'notification-service-files',
  // s3url: 'https://xox-superapp.s3.amazonaws.com/',

  clientId: 'a9zPpbltfVC5yE',
  clientSecret:
    'n1fTuiEKRPSEk1iTjOZLN056mB58ZGamoG3CUs2UTy6DMEOKqdZpYuKyZNvmnBmsj',
};
export default config;
